import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../../Context';
import './Colors.css';

export default function Colors({ data }) {
  const { language } = useContext(Context);
  const [currentPhoto, setCurrentPhoto] = useState(data[0]);

  useEffect(() => {
    setCurrentPhoto(data[0]);
  }, [data]);

  const handleNextPhoto = () => {
    const currentIndex = data.indexOf(currentPhoto);
    const nextIndex = (currentIndex + 1) % data.length;
    setCurrentPhoto(data[nextIndex]);
  };

  return (
    <div className="item__colors">
      <div className="item__colors-wrapper">
        <div className="item__colors-title">{language === 'укр' ? 'Також можливі кольорові варіанти:' : 'Also available in colors:'}</div>
        <div className="item__colors-img">
          <img src={currentPhoto} alt="foto colors" onClick={handleNextPhoto} />
          <div className="item__colors-navigate">
            {data.map((photo, index) => (
              <button
                key={index}
                onClick={() => setCurrentPhoto(photo)}
                className={`item__colors-navigate-item ${currentPhoto === photo ? 'item__colors-navigate-item-active' : ''}`}
              ></button>
            ))}
          </div>
        </div>
        <p className="item__colors-discription">
          {language === 'укр' ? (
            <>
              Bentley Bentayga – це не просто автомобіль, це втілення розкоші та вишуканості. Власники Bentayga цінують неперевершену якість, увагу до деталей та міць, приховану за
              елегантними лініями цього позашляховика. У Car Couture ми розробили ексклюзивний обвіс для Bentley Bentayga, щоб підкреслити та посилити унікальні характеристики цієї
              моделі. Наш комплект ідеально інтегрується з естетикою автомобіля, додаючи агресивні акценти та посилюючи динамічний образ, властивий Bentayga.
            </>
          ) : (
            <>
              Bentley Bentayga is not just a car, it embodies luxury and sophistication. Owners of the Bentayga appreciate unmatched quality, attention to detail, and strength
              hidden behind the elegant lines of this SUV. At Car Couture, we have developed an exclusive body kit for the Bentley Bentayga to emphasize and enhance the unique
              characteristics of this model. Our kit integrates perfectly with the car's aesthetics, adding aggressive accents and reinforcing the dynamic image typical of the
              Bentayga.
            </>
          )}
        </p>
      </div>
    </div>
  );
}
