import React, { useContext } from 'react';
import { Context } from '../../Context';
import { Link, useLocation } from 'react-router-dom';
import './Links.css';

export default function Links() {
  const location = useLocation();
  const { language } = useContext(Context);

  // Объекты с информацией о страницах на двух языках
  const pages = [
    {
      path: '/desart',
      title: 'Mercedes Benz G-Class',
      imgUrl: '/img/desart.png',
      description: {
        eng: 'Legend on wheels, historical flagship in the world of cars.',
        укр: 'легенда на колесах, історичний флагман у світі авто.',
      },
    },
    {
      path: '/bentley-continental',
      title: 'Bentley Continental',
      imgUrl: '/img/bentley-continental.png',
      description: {
        eng: 'Embodiment of luxury and prestigious style in the world of cars.',
        укр: 'Втілення розкоші та престижного стилю у світі автомобілів.',
      },
    },
    {
      path: '/bentley-bentayga',
      title: 'Bentley Bentayga',
      imgUrl: '/img/bentley-bentayga.png',
      description: {
        eng: 'Unmatched quality, attention to detail, and strength.',
        укр: 'Неперевершена якість, увага до деталей та міць',
      },
    },
    {
      path: '/mercedes-benz',
      title: 'Mercedes Benz',
      imgUrl: '/img/mercedes-benz.png',
      description: {
        eng: 'The car that revolutionized the concept of modern electric vehicles.',
        укр: 'автомобіль, який перевернув уявлення про сучасні електромобілі.',
      },
    },
  ];

  // Фильтрация текущей страницы из списка страниц
  const currentPath = location.pathname.replace('/en', '');
  const filteredPages = pages.filter((page) => page.path !== currentPath);

  return (
    <section className="links">
      <div className="links__title">{language === 'укр' ? 'ВАМ ТАКОЖ МОЖЕ СПОДОБАТИСЬ:' : 'YOU MAY ALSO LIKE:'}</div>

      <div className="links__wrapper">
        {filteredPages.slice(0, 3).map((page) => (
          <Link key={page.path} to={language === 'укр' ? page.path : `/en${page.path}`} className="links__item">
            <div className="links__item-img">
              <img src={page.imgUrl} alt="car" />
            </div>
            <div className="links__item-title">{page.title}</div>
            <div className="links__item-description">{language === 'укр' ? page.description.укр : page.description.eng}</div>
          </Link>
        ))}
      </div>
    </section>
  );
}
