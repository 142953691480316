import React, { useContext } from 'react';
import { Context } from '../../Context';
import { Link } from 'react-router-dom';
import './NotFound.css';

export default function NotFound() {
   const { language } = useContext(Context);
  return (
    <div className="notFound">
      <p className="notFound__text">{language === 'укр' ? 'вибачте, схоже цієї сторінки не існує' : 'Sorry, this page doesn`t seem to exist'}</p>
      <Link to={language === 'укр' ? '/' : '/en/'} className="notFound__button">
        {language === 'укр' ? 'перейти на головну' : 'go to the main page'}
      </Link>
    </div>
  );
}
