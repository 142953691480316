import React, { useContext } from 'react';
import { Context } from '../../Context';
import './Privacy.css';

export default function Privacy() {
  const { language } = useContext(Context);

  const translations = {
    укр: {
      title: 'Політика конфіденційності',
      intro1: 'Політика конфіденційності персональної інформації діє щодо всієї інформації, яку надають під час використання веб-сайту',
      intro2: 'та інших способів передачі особистої інформації.',
      registration:
        'Добровільна реєстрація користувача на сайті означає згоду на автоматизовану, а також без використання засобів автоматизації, обробку та використання своїх персональних даних, а також гарантує достовірність наданих своїх персональних даних.',
      userInfo: 'Персональна інформація користувача',
      userInfoDetails: 'В рамках цієї Політики під "персональною інформацією користувача" розуміються:',
      userInfoList: [
        'Персональна інформація, яку користувач надає про себе самостійно при реєстрації (створенні облікового запису), інша інформація надається користувачем на його розсуд, дані, які автоматично передаються в процесі їх використання за допомогою встановленого на пристрої користувача програмного забезпечення, у тому числі IP-адреси, інформація з cookie, інформація про браузер користувача (або іншу програму, за допомогою якої здійснюється доступ до Сервісів), час доступу, адреса сторінки, що запитується.',
      ],
      purposes: 'Цілі збору та використання персональних даних',
      purposesList: [
        'Ідентифікація користувача на веб-сайті',
        "Зв'язок з користувачем, направлення повідомлень, запитів та інформації, обробка запитів від користувача",
        'Таргетування рекламних матеріалів, проведення статистичних та інших досліджень',
        'Загальні відомості щодо переміщень на сайті для оптимізації контенту',
      ],
      confidentiality: 'Конфіденційність персональної інформації',
      confidentialityDetails:
        'Компанія СarCouture зберігає конфіденційність персональної інформації Користувача, крім випадків добровільного надання їм інформації про себе для загального доступу необмеженому колу осіб при використанні сторонніх сервісів (наприклад, Facebook, Instagram тощо), користувач погоджується з тим, що певна частина його персональної інформації стає загальнодоступною.',
      socialMedia:
        'Користувач усвідомлює, що у разі авторизації на веб-сайті за допомогою облікових записів соціальних мереж, на нього поширюються правила та умови відповідних соціальних мереж, у тому числі щодо обробки та використання персональних даних та забезпечення їх конфіденційності.',
      thirdParties: 'Передача персональної інформації третім особам',
      thirdPartiesList: [
        'Користувач висловив свою згоду на таку дію',
        'Передача необхідна у межах використання Користувачем певного Сервісу або надання послуги користувачеві, зокрема доставки товарів',
        'З метою виконання вимог закону чи рішень суду',
      ],
      rights: 'Користувач може будь-якої миті змінити надану їм персональну інформацію або подати запит на повне видалення.',
      security: 'Заходи захисту персональної інформації',
      securityDetails:
        'Компанія СarCouture вживає необхідних організаційних та технічних заходів для захисту персональної інформації Користувача від неправомірного доступу, знищення, зміни, блокування, копіювання, розповсюдження третіх осіб.',
      complaints: 'Особи, які вважають, що їх права на використання персональних даних порушено, можуть направити претензії на адресу електронної пошти',
    },
    eng: {
      title: 'Privacy Policy',
      intro1: 'The privacy policy of personal information applies to all information provided when using the website',
      intro2: 'and other ways of transmitting personal information.',
      registration:
        'Voluntary registration of the user on the website means consent to the automated, as well as without the use of automation tools, processing and use of their personal data, and also guarantees the accuracy of the personal data provided.',
      userInfo: 'User Personal Information',
      userInfoDetails: 'Within the framework of this Policy, "user personal information" means:',
      userInfoList: [
        "Personal information that the user provides about themselves during registration (creating an account), other information provided by the user at their discretion, data that is automatically transmitted in the process of their use using software installed on the user's device, including IP addresses, information from cookies, information about the user's browser (or other program used to access the Services), access time, requested page address.",
      ],
      purposes: 'Purposes of Collecting and Using Personal Data',
      purposesList: [
        'Identification of the user on the website',
        'Communication with the user, sending messages, requests and information, processing requests from the user',
        'Targeting advertising materials, conducting statistical and other research',
        'General information about movements on the site to optimize content',
      ],
      confidentiality: 'Confidentiality of Personal Information',
      confidentialityDetails:
        "CarCouture keeps the confidentiality of the user's personal information, except for cases of voluntary provision of information about themselves for general access to an unlimited number of persons when using third-party services (for example, Facebook, Instagram, etc.), the user agrees that certain parts of their personal information become publicly available.",
      socialMedia:
        'The user understands that if they authorize on the website using social media accounts, they are subject to the rules and conditions of the respective social media, including the processing and use of personal data and ensuring their confidentiality.',
      thirdParties: 'Transfer of Personal Information to Third Parties',
      thirdPartiesList: [
        'The user has expressed their consent to such actions',
        "The transfer is necessary within the framework of the user's use of a specific service or provision of services to the user, including the delivery of goods",
        'In order to comply with the requirements of the law or court decisions',
      ],
      rights: 'The user can change the personal information provided by them at any time or request its complete deletion.',
      security: 'Measures to Protect Personal Information',
      securityDetails:
        "CarCouture takes necessary organizational and technical measures to protect the user's personal information from unauthorized access, destruction, modification, blocking, copying, distribution by third parties.",
      complaints: 'Persons who believe that their rights to use personal data have been violated can send claims to the email address',
    },
  };

  const t = translations[language];

  return (
    <div className="privacy">
      <h1 className="privacy__title">{t.title}</h1>

      <h2>{t.intro1}</h2>
      <p>
        {t.intro1}{' '}
        <a target="_blank" rel="noreferrer" href="https://carcouture.com.ua">
          https://carcouture.com.ua
        </a>{' '}
        {t.intro2}
      </p>
      <p>
        <a target="_blank" rel="noreferrer" href="https://carcouture.com.ua">
          https://carcouture.com.ua
        </a>{' '}
        {t.registration}
      </p>

      <h2>{t.userInfo}</h2>
      <p>{t.userInfoDetails}</p>
      <ul>
        {t.userInfoList.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>

      <h2>{t.purposes}</h2>
      <p>{t.purposes}</p>
      <ul>
        {t.purposesList.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>

      <h2>{t.confidentiality}</h2>
      <p>{t.confidentialityDetails}</p>
      <p>
        <a target="_blank" rel="noreferrer" href="https://carcouture.com.ua">
          https://carcouture.com.ua
        </a>{' '}
        {t.socialMedia}
      </p>

      <h2>{t.thirdParties}</h2>
      <p>{t.thirdParties}</p>
      <ul>
        {t.thirdPartiesList.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
      <p>{t.rights}</p>

      <h2>{t.security}</h2>
      <p>{t.securityDetails}</p>
      <p>
        <a target="_blank" rel="noreferrer" href="mailto:levchukys@gmail.com">
          levchukys@gmail.com
        </a>{' '}
        {t.complaints}
      </p>
    </div>
  );
}
