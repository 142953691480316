import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MainRoute from './pages/MainRoute';
import { Context } from './Context';
import { useState } from 'react';
import './fonts.css';
import './App.css';

function App() {
  // языки для сайта
  const [language, setLanguage] = useState('укр');

  return (
    <Context.Provider
      value={{
        language, setLanguage
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<MainRoute />} />
        </Routes>
      </BrowserRouter>
    </Context.Provider>
  );
}

export default App;
