import React, { useContext, useState } from 'react';
import { Context } from '../../Context';
import { useSwipeable } from 'react-swipeable';
import './News.css';

const newsItems = {
  eng: [
    {
      date: '12.07.2024',
      title: 'The new Mercedes-Benz G-Class 2024',
      description:
        'The new Mercedes-Benz G-Class 2024 continues the legacy of the 1979 model, retaining its body-on-frame construction, three differential locks, and LOW RANGE transmission for off-road driving. The G-Class has seen significant technological improvements.',
      imgSrc: '/img/news1.jpg',
    },
    {
      date: '13.07.2024',
      title: 'The EQS sedan gets major updates.',
      description:
        'The EQS sedan`s Drive Pilot controls speed and lane up to 40 mph, available in California and Nevada. 2024 updates include more power, range, improved brakes, standard MBUX Hyperscreen, and power-adjustable rear seats.',
      imgSrc: '/img/news2.png',
    },
  ],
  укр: [
    {
      date: '12.07.2024',
      title: 'Новий Mercedes-Benz G-Class 2024',
      description:
        'Новий Mercedes-Benz G-Class 2024 продовжує історію моделі 1979 року, зберігаючи рамну конструкцію, три диференціали та передачу LOW RANGE для бездоріжжя. G-Class отримав значні технологічні поліпшення.',
      imgSrc: '/img/news1.jpg',
    },
    {
      date: '13.07.2024',
      title: 'Седан EQS отримав значні оновлення.',
      description:
        'Седан EQS отримав систему Drive Pilot, яка контролює швидкість і смугу до 40 миль/год, доступна в Каліфорнії та Неваді. Оновлення 2024 року включають більше потужності, запас ходу, покращені гальма, стандартний MBUX Hyperscreen та електрорегульовані задні сидіння.',
      imgSrc: '/img/news2.png',
    },
  ],
};

export default function News() {
 const { language } = useContext(Context);
 const [currentNewsIndex, setCurrentNewsIndex] = useState(0);

 const handlePrevious = () => {
   setCurrentNewsIndex((prevIndex) => (prevIndex === 0 ? newsItems[language].length - 1 : prevIndex - 1));
 };

 const handleNext = () => {
   setCurrentNewsIndex((prevIndex) => (prevIndex === newsItems[language].length - 1 ? 0 : prevIndex + 1));
 };

 const handlers = useSwipeable({
   onSwipedLeft: handleNext,
   onSwipedRight: handlePrevious,
 });

 const currentNews = newsItems[language][currentNewsIndex];

  return (
    <section className="news" {...handlers}>
      <div className="news__title">{language === 'укр' ? 'Наші новини' : 'Our News'}</div>
      <p className="news__description">
        {language === 'укр' ? 'Тут ви зможете дізнатись про всі події, які стались останнім часом.' : 'Here you can learn about all the events that have happened recently.'}
      </p>

      <div className="news__wrapper">
        <button className="news__arrow news__arrow-left" onClick={handlePrevious}>
          <img className="img-contain" src="/icon/arrow.svg" alt="arrow" />
        </button>

        <div className="news__info">
          <div className="news__info-date">
            <div className="news__info-date-numer">{currentNews.date}</div>
            <div className="news__info-date-numer">{language === 'укр' ? 'Новина' : 'News'}</div>
          </div>

          <div className="news__info-img">
            <img className="news__info-img-black" src="/img/black.svg" alt="news" />
            <img src={currentNews.imgSrc} alt="news" />
          </div>

          <div className="news__info-text">
            <div className="news__info-title">{currentNews.title}</div>
            <div className="news__info-description">{currentNews.description}</div>
          </div>
        </div>

        <button className="news__arrow" onClick={handleNext}>
          <img className="img-contain" src="/icon/arrow.svg" alt="arrow" />
        </button>
      </div>
    </section>
  );
}
