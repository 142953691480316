import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Context } from '../../Context';
import { useMediaQuery } from 'react-responsive';
import './Footer.css';

const translations = {
  укр: {
    aboutUs: 'про нас',
    aboutUsLink: '/about-us',
    instagram: 'instagram',
    privacyPolicy: 'Політика конфіденційності',
    privacyPolicyLink: '/privacy-policy',
  },
  eng: {
    aboutUs: 'about us',
    aboutUsLink: '/en/about-us',
    instagram: 'instagram',
    privacyPolicy: 'Privacy Policy',
    privacyPolicyLink: '/en/privacy-policy',
  },
};

export default function Footer() {
  const { language } = useContext(Context);
  const t = translations[language];

  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <div name="footer" className="footer">
      <div className="footer-img">
        <img src={isMobile ? '/img/footer-mobile.png' : '/img/footer.png'} alt="img" />
      </div>

      <div className="footer__link">
        <a href="tel:+380676867244">+38 067 686 72 44</a>
        <a href="tel:+380509453661">+38 050 945 36 61</a>
        <a href="mailto:levchukys@gmail.com">levchukys@gmail.com</a>
      </div>

      <div className="footer__info">
        <div className="footer__info-links">
          <Link to={t.aboutUsLink}>{t.aboutUs}</Link>
          <a href="https://www.instagram.com/car.couture.ua?igsh=MXM5dHY3MTJhbHQyeg==" target="blank">
            {t.instagram}
          </a>
          <Link to={t.privacyPolicyLink}>{t.privacyPolicy}</Link>
        </div>
        <div className="footer__info-other">
          <div>© 2024 car couture</div>
          <a href="https://circle.in.ua" target="blank">
            made by circle studio
          </a>
        </div>
      </div>
    </div>
  );
}
