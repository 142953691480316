import React, { useContext } from 'react';
import Inputs from '../../components/Inputs/Inputs';
import News from '../../components/News/News';
import { Context } from '../../Context';
import { Link as RouterLink } from 'react-router-dom';
import { Link as ScrollLink, Element } from 'react-scroll';
import './Main.css';

const translations = {
  укр: {
    title: 'тюнінг авто та підвіси',
    consultationButton: 'записатись на консультацію',
    specialTitle: 'особливості нашого сервісу',
    specialDescription: 'люксовий бренд обвісів для автомобілів Mercedes та Bеntley.',
    specialImgTitle: 'обвіси власної розробки та виробництва',
    specialImgDescription:
      'МИ ПІДКРЕСЛЮЄМО ЯСКРАВІСТЬ ТА ІНДИВІДУАЛЬНІСТЬ. ДЛЯ КОГОСЬ ОБВІСИ — ЦЕ ЕЛЕМЕНТ ДЕКОРУ, ДЛЯ НАС — МИСТЕЦТВО. НАША МЕТА - НАДАТИ НЕЗАБУТНІЙ ВИГЛЯД ВАШОМУ АВТО ПРИ МАКСИМАЛЬНО КОМФОРТНИХ УМОВАХ.',
  },
  eng: {
    title: 'car tuning and suspensions',
    consultationButton: 'sign up for a consultation',
    specialTitle: 'our service features',
    specialDescription: 'luxury brand kits for Mercedes and Bentley cars.',
    specialImgTitle: 'in-house designed and manufactured kits',
    specialImgDescription:
      'WE LOVE CREDIT AND INDIVIDUALITY. FOR WHOM SAGGING IS AN ELEMENT OF DECOR, FOR US IT IS MYSTICITY. OUR META IS TO GIVE A UNFORGETTABLE LOOK TO YOUR CAR WITH THE MAXIMUM COMFORTABLE MIND.',
  },
};

export default function Main() {
  const { language } = useContext(Context);
  const t = translations[language];

  const carData = [
    {
      brand: 'Mercedes Benz',
      brandImg: '/img/mercedes-brand.svg',
      model: 'EQS 450',
      description:
        language === 'укр'
          ? 'Це автомобіль, який перевернув уявлення про сучасні електромобілі. Його революційний дизайн та передові технології зробили його еталоном у світі преміальних автомобілів.'
          : 'This is a car that has revolutionized the concept of modern electric vehicles. Its revolutionary design and advanced technologies have made it a benchmark in the world of premium cars.',
      left: false,
      imgSrc: '/img/mercedes-benz.png',
      link: language === 'укр' ? '/mercedes-benz' : '/en/mercedes-benz',
    },
    {
      brand: 'Bentley Continental',
      brandImg: '/img/bentley-brand.svg',
      model: 'Flying Spur',
      description:
        language === 'укр'
          ? 'Втілення розкоші та престижного стилю у світі автомобілів. Цей видатний седан привертає увагу завдяки своїй елегантності, потужності та витонченому дизайну.'
          : 'The embodiment of luxury and prestigious style in the world of cars. This outstanding sedan draws attention with its elegance, power and refined design.',
      left: true,
      imgSrc: '/img/bentley-continental.png',
      link: language === 'укр' ? '/bentley-continental' : '/en/bentley-continental',
    },
    {
      brand: 'Bentley',
      brandImg: '/img/bentley-brand.svg',
      model: 'Bentayga',
      description:
        language === 'укр'
          ? 'Це не просто автомобіль, це втілення розкоші та вишуканості. Власники Bentayga цінують неперевершену якість, увагу до деталей та міць, приховану за елегантними лініями цього позашляховика.'
          : 'This is not just a car, it is the embodiment of luxury and sophistication. Bentayga owners appreciate the unparalleled quality, attention to detail, and power hidden behind the elegant lines of this SUV.',
      left: false,
      imgSrc: '/img/bentley-bentayga.png',
      link: language === 'укр' ? '/bentley-bentayga' : '/en/bentley-bentayga',
    },
    {
      brand: 'Mercedes Benz',
      brandImg: '/img/mercedes-brand.svg',
      model: 'G-Class',
      description:
        language === 'укр'
          ? 'Це не просто автомобіль, це легенда на колесах, історичний флагман у світі авто. Брутальний, строгий і володіє неймовірною енергетикою, G-Class став символом потужності та неперевершеного стилю.'
          : 'This is not just a car, it is a legend on wheels, a historical flagship in the automotive world. Brutal, strict and with incredible energy, the G-Class has become a symbol of power and unparalleled style.',
      left: true,
      imgSrc: '/img/desart.png',
      link: language === 'укр' ? '/desart' : '/en/desart',
    },
  ];

  return (
    <main className="main">
      <section className="main__img">
        <h1 className="main__img-title">{t.title}</h1>
        <ScrollLink to="form" smooth={true} duration={500} offset={-150} className="main__img-button">
          {t.consultationButton}
        </ScrollLink>
      </section>

      <section className="main__special">
        <h2 className="main__special-title">{t.specialTitle}</h2>
        <p className="main__special-description">{t.specialDescription}</p>
        <div className="main__special-img">
          <div className="main__special-img-wrapper">
            <h2 className="main__special-img-title">{t.specialImgTitle}</h2>
            <p className="main__special-img-description">{t.specialImgDescription}</p>
          </div>
        </div>
      </section>

      {carData.map((car, index) => (
        <RouterLink to={car.link} key={index} className={`main__car ${car.left ? 'main__car-left' : ''}`}>
          <div className="main__car-info">
            <div className={`main__car-info-wrapper ${car.left ? 'main__car-info-wrapper-left' : ''}`}>
              <div
                className={`main__car-info-icon ${
                  car.brand === 'Bentley' || car.brand === 'Bentley Continental' ? 'main__car-info-icon-bentley' : 'main__car-info-icon-bentley-mercedes'
                }`}
              >
                <img className="img-contain" src={car.brandImg} alt="brand" />
              </div>
              <div className="main__car-info-text">
                <div className="main__car-info-text-title">
                  {car.brand} <br /> {car.model}
                </div>
                <div className="main__car-info-text-description">{car.description}</div>
              </div>
              <div className="main__car-info-arrow">
                <img className="img-contain" src="/icon/arrow.svg" alt="arrow" />
              </div>
            </div>
          </div>
          <div className={`main__car-img ${car.left ? 'main__car-img-left' : ''}`} style={{ backgroundImage: `url(${car.imgSrc})` }} />
        </RouterLink>
      ))}

      <Element name="form">
        <Inputs />
      </Element>

      <News />
    </main>
  );
}
