import React, { useContext } from 'react';
import Inputs from '../../components/Inputs/Inputs';
import Links from '../../components/Links/Links';
import { Context } from '../../Context';
import { Link } from 'react-scroll';
import './About.css';

const translations = {
  eng: {
    aboutUs: 'About Us',
    carCouture: 'CarCouture – a Ukrainian luxury brand of car body kits.',
    offer: 'We offer custom-designed and manufactured body kits for Mercedes Benz, Bentley, and other premium cars.',
    tuning: 'We specialize in car tuning and restyling. We promote brightness and individuality. For some, body kits are a decorative element, for us – It’s an art.',
    goal: 'Our goal is to give your car an unforgettable look under the most comfortable conditions.',
    consultation: 'Book a consultation',
    whyUs: 'Why Us?',
    fullService: 'Providing service from A to Z, from development to installation',
    turnkey: 'Turnkey installation',
    individualization: 'Possible individualization of products',
    production: 'Production in Ukraine',
    availability: 'Are always in stock',
    processTitle: 'Work steps',
    fullSupport: 'Full Support',
    fullSupportDescription:
      'Development, production, and installation of BodyKit on a turnkey basis. Dismantling of standard components and installation of exclusive author body kits.',
    exteriorInterior: 'Work on Exterior and Interior',
    exteriorInteriorDescription:
      'Detailing of exterior and interior, car body polishing, adding protective and colored films, tuning the car interior and exhaust system, soundproofing.',
    personalization: 'Personalization',
    personalizationDescription: 'Painting the body kit in the chosen color scheme.',
    comfort: 'Your Comfort Above All',
    comfortDescription: 'Installation in the city specified by the client.',
  },
  укр: {
    aboutUs: 'Про нас',
    carCouture: 'CarCouture – український люксовий бренд обвісів для авто.',
    offer: 'Пропонуємо обвіси власної розробки та виробництва для автомобілів Mersedes Benz, Bentley та інших преміальних авто.',
    tuning: 'Ми займаємося тюнінгом та рестайлінгом авто. Пропонуємо яскравість та індивідуальність. Для когось обвіси – це елемент декору, для нас – мистецтво.',
    goal: 'Наше завдання полягає в тому, щоб надати незабутній вигляд вашому авто за максимально комфортних умов.',
    consultation: 'записатись на консультацію',
    whyUs: 'Чому саме ми?',
    fullService: 'Надання сервісу від А до Я, від розробки до встановлення',
    turnkey: 'Встановлення виробів під ключ',
    individualization: 'Можлива індивідуалізація виробів',
    production: 'Виробництво в Україні',
    availability: 'Обвіси завжди є в наявності',
    processTitle: 'Послідовність роботи',
    fullSupport: 'Повний супровід',
    fullSupportDescription: 'Розробка, виробництво і встановлення BodyKit під ключ. Демонтаж стандартних компонентів і встановлення авторського ексклюзивного обвісу.',
    exteriorInterior: 'Робота над екстер’єром та інтер’єром',
    exteriorInteriorDescription:
      'Дітейлінг екстер’єру та інтер’єру, полірування кузова автомобіля, додавання захисної та кольорової плівок, тюнінг салону автомобіля та вихлопної системи авто, шумоізоляція.',
    personalization: 'Персоналізація',
    personalizationDescription: 'Фарбування обвісу у обрану кольорову гаму.',
    comfort: 'Твій комфорт понад усе',
    comfortDescription: 'Монтаж у зазначенному клієнтом місті.',
  },
};

export default function About() {
  const { language } = useContext(Context);
  const t = translations[language];

  return (
    <div className="about">
      <div className="about__img">
        <img className="item__img-item" src="/img/about.png" alt={t.aboutUs} />
      </div>
      <h1 className="about__title">{t.aboutUs}</h1>
      <div className="about__info">
        <div className="about__info-wrapper">
          <div className="about__info-wrapper-img">
            <img src="/img/about-info.png" alt="about" />
          </div>
          <div className="about__info-wrapper-info">
            <div className="about__info-logo">
              <img className="img-contain" src="/icon/logo.svg" alt="about-logo" />
            </div>
            <p>{t.carCouture}</p>
            <p>{t.offer}</p>
            <p>{t.tuning}</p>
            <p>{t.goal}</p>
            <Link to="form" smooth={true} duration={500} offset={-150} className="about__info-wrapper-info-button">
              {t.consultation}
            </Link>
          </div>
        </div>
        <div className="about__ansver">
          <h2 className="about__ansver-title">{t.whyUs}</h2>
          <div className="about__ansver-wrapper">
            <p>{t.fullService}</p>
            <p>{t.turnkey}</p>
            <p>{t.individualization}</p>
            <p>{t.production}</p>
            <p>{t.availability}</p>
          </div>
        </div>
        <div className="about__next">
          <h2 className="about__ansver-title">{t.processTitle}</h2>
          <div className="about__next-wrapper">
            <div className="about__next-block">
              <div className="about__next-block-item-left">
                <div className="about__next-block-item-number">01</div>
                <div className="about__next-block-item-title">{t.fullSupport}</div>
                <div className="about__next-block-item-description">{t.fullSupportDescription}</div>
              </div>
              <div className="about__next-block-item">
                <div className="about__next-block-item-number">02</div>
                <div className="about__next-block-item-title">{t.exteriorInterior}</div>
                <div className="about__next-block-item-description">{t.exteriorInteriorDescription}</div>
              </div>
            </div>
            <div className="about__next-block">
              <div className="about__next-block-item-left">
                <div className="about__next-block-item-number">03</div>
                <div className="about__next-block-item-title">{t.personalization}</div>
                <div className="about__next-block-item-description">{t.personalizationDescription}</div>
              </div>
              <div className="about__next-block-item">
                <div className="about__next-block-item-number">04</div>
                <div className="about__next-block-item-title">{t.comfort}</div>
                <div className="about__next-block-item-description">{t.comfortDescription}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about__next-inputs">
        <Inputs />
        <Links />
      </div>
    </div>
  );
}
