import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../../Context';
import { handleLanguage } from '../../functions/ChangeLanguage';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import './Header.css';

export default function Header() {
  const { language, setLanguage } = useContext(Context);

  const [openLinks, setOpenLinks] = useState(false);

  const [burger, setBurger] = useState(false);

  const location = useLocation();

  const navigate = useNavigate();

  // устанавливаем язык
  useEffect(() => {
    const pathParts = location.pathname.split('/');
    if (pathParts[1] === 'en') {
      setLanguage('eng');
    } else if (pathParts[0] === '') {
      setLanguage('укр');
    }
    // eslint-disable-next-line
  }, [location]);

  const handleClickBurgerOpen = () => {
    setBurger(true);
    document.body.style.overflow = 'hidden';
  };

  const handleClickBurgerClose = () => {
    setBurger(false);
    document.body.style.overflow = 'scroll';
  };

  return (
    <header className="header">
      <div onClick={() => handleClickBurgerClose()} className={`header__burger-black ${burger ? 'header__burger-black-active' : ''}`}></div>
      <div className={`header__burger-window ${burger ? 'header__burger-window-active' : ''}`}>
        <div className="header__burger-window-open-logo">
          <div className="header__left-logo">
            <img className="img-contain" src="/icon/logo.svg" alt="logo" />
          </div>
          <div onClick={() => handleClickBurgerClose()} className="header__left-logo-close">
            <img className="img-contain" src="/icon/close.svg" alt="close" />
          </div>
        </div>

        <ul className="header__burger-window-nav">
          <li className="header__burger-window-nav-item">
            <RouterLink onClick={() => handleClickBurgerClose()} to={language === 'укр' ? '/about-us' : '/en/about-us'}>
              {language === 'укр' ? 'про нас' : 'about us'}
            </RouterLink>
          </li>

          <li className="header__burger-window-nav-item">
            <ScrollLink onClick={() => handleClickBurgerClose()} smooth={true} duration={500} to="footer">
              {language === 'укр' ? 'контакти' : 'contacts'}
            </ScrollLink>
          </li>

          <li>
            <RouterLink onClick={() => handleClickBurgerClose()} to={language === 'укр' ? '/mercedes-benz' : '/en/mercedes-benz'}>
              Mercedes Benz EQS 450
            </RouterLink>
          </li>
          <li>
            <RouterLink onClick={() => handleClickBurgerClose()} to={language === 'укр' ? '/bentley-continental' : '/en/bentley-continental'}>
              Bentley Continental Flying Spur
            </RouterLink>
          </li>
          <li>
            <RouterLink onClick={() => handleClickBurgerClose()} to={language === 'укр' ? '/bentley-bentayga' : '/en/bentley-bentayga'}>
              Bentley Bentayga
            </RouterLink>
          </li>
          <li>
            <RouterLink onClick={() => handleClickBurgerClose()} to={language === 'укр' ? '/desart' : '/en/desart'}>
              Mercedes G-Class
            </RouterLink>
          </li>

          <div className="header__right-tel">
            <a href="tel:+380676867244">+38 067 686 72 44</a>
            <a href="tel:+380509453661">+38 050 945 36 61</a>
          </div>

          <ul className="header__right-language">
            {['укр', 'eng'].map((item, index) => (
              <li
                onClick={() => handleLanguage(language, location, navigate, item)}
                className={`header__right-language-item ${item === language ? 'header__right-language-active' : ''}`}
                key={index}
              >
                {item}
              </li>
            ))}
          </ul>
        </ul>
      </div>

      <nav className="header__left">
        <RouterLink to={language === 'укр' ? '/' : '/en/'} className="header__left-logo">
          <img className="img-contain" src="/icon/logo.svg" alt="logo" />
        </RouterLink>

        <ul className="header__left-nav">
          <li className="header__left-nav-item">
            <RouterLink to={language === 'укр' ? '/about-us' : '/en/about-us'}>{language === 'укр' ? 'про нас' : 'about us'}</RouterLink>
          </li>
          <li className="header__left-nav-item">
            <ScrollLink smooth={true} duration={500} to="footer">
              {language === 'укр' ? 'контакти' : 'contacts'}
            </ScrollLink>
          </li>

          <li className="header__left-nav-item text-no-touch">
            <div onClick={() => setOpenLinks(!openLinks)}>{language === 'укр' ? 'наші роботи' : 'our works'}</div>
            {openLinks && (
              <ul
                onMouseLeave={() => {
                  const timeoutId = setTimeout(() => {
                    setOpenLinks(false);
                  }, 700);
                  return () => clearTimeout(timeoutId);
                }}
                className="header__left-nav-item-links"
              >
                <li>
                  <RouterLink onClick={() => setOpenLinks(false)} to={language === 'укр' ? '/mercedes-benz' : '/en/mercedes-benz'}>
                    Mercedes Benz EQS 450
                  </RouterLink>
                </li>
                <li>
                  <RouterLink onClick={() => setOpenLinks(false)} to={language === 'укр' ? '/bentley-continental' : '/en/bentley-continental'}>
                    Bentley Continental Flying Spur
                  </RouterLink>
                </li>
                <li>
                  <RouterLink onClick={() => setOpenLinks(false)} to={language === 'укр' ? '/bentley-bentayga' : '/en/bentley-bentayga'}>
                    Bentley Bentayga
                  </RouterLink>
                </li>
                <li>
                  <RouterLink onClick={() => setOpenLinks(false)} to={language === 'укр' ? '/desart' : '/en/desart'}>
                    Mercedes G-Class
                  </RouterLink>
                </li>
              </ul>
            )}
          </li>
        </ul>
      </nav>

      <div className="header__right">
        <a className="header__right-tel" href="tel:+380676867244">
          +38 067 686 72 44
        </a>

        <ul className="header__right-language">
          {['укр', 'eng'].map((item, index) => (
            <li
              onClick={() => handleLanguage(language, location, navigate, item)}
              className={`header__right-language-item ${item === language ? 'header__right-language-active' : ''}`}
              key={index}
            >
              {item}
            </li>
          ))}
        </ul>
      </div>

      <div onClick={() => handleClickBurgerOpen()} className="header__burger-img">
        <img src="/icon/burger.svg" alt="burger" />
      </div>
    </header>
  );
}
