import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Container from './Container/Container';
import ScrollToTop from '../functions/ScrollToTop';
import Main from './Main/Main';
import Item from './Item/Item';
import About from './about/About';
import NotFound from './NotFound/NotFound';
import Privacy from './Privacy/Privacy';

export default function MainRoute() {
  return (
    <Container>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/en" element={<Main />} />
        <Route path="/*" element={<NotFound />} />

        <Route path="/desart" element={<Item />} />
        <Route path="/en/desart" element={<Item />} />

        <Route path="/bentley-continental" element={<Item />} />
        <Route path="/en/bentley-continental" element={<Item />} />

        <Route path="/bentley-bentayga" element={<Item />} />
        <Route path="/en/bentley-bentayga" element={<Item />} />

        <Route path="/mercedes-benz" element={<Item />} />
        <Route path="/en/mercedes-benz" element={<Item />} />

        <Route path="/about-us" element={<About />} />
        <Route path="/en/about-us" element={<About />} />

        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/en/privacy-policy" element={<Privacy />} />
      </Routes>
    </Container>
  );
}
