export const handleLanguage = (language, location, navigate, props) => {
  localStorage.setItem('car', props);

  const languageCode = location.pathname;
  const searchParams = new URLSearchParams(location.search);

  const languageObject = {
    'укр': '/',
    eng: '/en',
  };

  let newUrl;

  if (language === 'укр' && props !== 'укр') {
    newUrl = `${languageObject[props]}${languageCode}?${searchParams.toString()}`;
  } else if (props === 'укр' && language !== 'укр') {
    newUrl = languageCode.replace(/^\/\w+\//, '/');
    if (searchParams.toString()) {
      newUrl += `?${searchParams.toString()}`;
    }
  } else {
    newUrl = languageCode.replace(new RegExp(`${languageObject[language]}(?=/|$)`), `${languageObject[props]}`);
    if (searchParams.toString()) {
      newUrl += `?${searchParams.toString()}`;
    }
  }
  navigate(newUrl);
};
