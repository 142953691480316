export const pageData = {
  '/desart': {
    imgSrc: './img/desart-main.png',
    imgBrand: './img/desart-brand.svg',
    video: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/video%2Fdesart.MP4?alt=media&token=4c301c94-e1f6-48d5-aab1-3e40888eb853',
    title: 'Desart',
    description: 'обважування DESART для Mercedes G-Class',
    contentText: [
      'Історичний флагман з брутальним характером: унікальне обваження DESART для Mercedes G-Class',
      'Mercedes G-Class – це не просто автомобіль, це легенда на колесах, історичний флагман у світі авто. Брутальний, строгий і володіє неймовірною енергетикою, G-Class став символом потужності та неперевершеного стилю.',
      'Компанія Car Couture пропонує ексклюзивне обважування DESART для Mercedes G-Class, яке підкреслює його потужний і суворий характер, додаючи ще більше виразності та сили.',
      'DESART від Car Couture для Mercedes G-Class - це не просто зовнішній тюнінг, це справжнє вираження філософії Mercedes, що поєднує в собі традиції.',
    ],
    infoImgSrc: './img/desart-info.png',
    details: [
      {
        title: 'Вставка в окуляри на фари 2 шт.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Desart%2Fdetails%2F%D0%92%D1%81%D1%82%D0%B0%D0%B2%D0%BA%D0%B0%20%D0%B2%20%D0%BE%D0%BA%D1%83%D0%BB%D1%8F%D1%80%D0%B8%20%D0%BD%D0%B0%20%D1%84%D0%B0%D1%80%D0%B8.jpg?alt=media&token=f3f3d45a-f68a-4a3d-b52d-6d3866419163',
      },
      {
        title: 'Вставки бічні у передній бампер 2 шт.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Desart%2Fdetails%2F%D0%92%D1%81%D1%82%D0%B0%D0%B2%D0%BA%D0%B8%20%D0%B1%D1%96%D1%87%D0%BD%D1%96%20%D1%83%20%D0%BF%D0%B5%D1%80%D0%B5%D0%B4%D0%BD%D1%96%D0%B8%CC%86%20%D0%B1%D0%B0%D0%BC%D0%BF%D0%B5%D1%80.jpg?alt=media&token=bda66bc1-82a4-4ef8-8189-b1331704024a',
      },
      {
        title: 'Вставка у задній бампер 1 шт.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Desart%2Fdetails%2F%D0%92%D1%81%D1%82%D0%B0%D0%B2%D0%BA%D0%B8%20%D1%83%20%D0%B7%D0%B0%D0%B4%D0%BD%D1%96%D0%B8%CC%86%20%D0%B1%D0%B0%D0%BC%D0%BF%D0%B5%D1%80.jpg?alt=media&token=0ebab2ba-8bce-4658-acfb-23d115d343df',
      },
      {
        title: 'Задній бампер 1 шт.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Desart%2Fdetails%2F%D0%97%D0%B0%D0%B4%D0%BD%D1%96%D0%B8%CC%86%20%D0%B1%D0%B0%D0%BC%D0%BF%D0%B5%D1%80.jpg?alt=media&token=edd38999-5979-4ef5-b021-1bdfb9d6a714',
      },
      {
        title: 'Накладка на дах ( під дхв ) 1 шт.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Desart%2Fdetails%2F%D0%9D%D0%B0%D0%BA%D0%BB%D0%B0%D0%B4%D0%BA%D0%B0%20%D0%BD%D0%B0%20%D0%B4%D0%B0%D1%85%20(%20%D0%BF%D1%96%D0%B4%20%D0%B4%D1%85%D0%B2%20)%201%20%D1%88%D1%82.JPG?alt=media&token=0708ab89-237e-453f-9f88-4123b3047ffa',
      },
      {
        title: 'Накладка на капот 1 шт.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Desart%2Fdetails%2F%D0%9D%D0%B0%D0%BA%D0%BB%D0%B0%D0%B4%D0%BA%D0%B0%20%D0%BD%D0%B0%20%D0%BA%D0%B0%D0%BF%D0%BE%D1%82.jpg?alt=media&token=75128969-be0e-4460-a8f7-fa4a7a27ac09',
      },
      {
        title: 'Накладки на пороги 4 шт.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Desart%2Fdetails%2F%D0%9D%D0%B0%D0%BA%D0%BB%D0%B0%D0%B4%D0%BA%D0%B8%20%D0%BD%D0%B0%20%D0%BF%D0%BE%D1%80%D0%BE%D0%B3%D0%B8.jpg?alt=media&token=f9ad82b2-06f8-4e94-a450-5d13d46bb6c6',
      },
      {
        title: 'Розширювачі арок ( переднi ) 2 шт.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Desart%2Fdetails%2F%D0%A0%D0%BE%D0%B7%D1%88%D0%B8%D1%80%D1%8E%D0%B2%D0%B0%D1%87%D1%96%20%D0%B0%D1%80%D0%BE%D0%BA%20%D0%BF%D0%B5%D1%80%D0%B5%D0%B4%D0%BDi.jpg?alt=media&token=5875a9c1-2ce8-4fc3-a10a-0c02cff731e3',
      },
      {
        title: 'Окуляри для фар 2 шт.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Desart%2Fdetails%2F%D0%9E%D0%BA%D1%83%D0%BB%D1%8F%D1%80%D0%B8%20%D0%B4%D0%BB%D1%8F%20%D1%84%D0%B0%D1%80.jpg?alt=media&token=a3609af7-8615-4dc3-893a-cec4358bbd3f',
      },
      {
        title: 'Передній бампер 1 шт.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Desart%2Fdetails%2F%D0%9F%D0%B5%D1%80%D0%B5%D0%B4%D0%BD%D1%96%D0%B8%CC%86%20%D0%B1%D0%B0%D0%BC%D0%BF%D0%B5%D1%80.jpg?alt=media&token=10061e7a-8d57-420d-8b72-0b3aa2f8a1b7',
      },
      {
        title: 'Решiтка радіатора 1 шт.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Desart%2Fdetails%2F%D0%A0%D0%B5%D1%88i%D1%82%D0%BA%D0%B0%20%D1%80%D0%B0%D0%B4%D1%96%D0%B0%D1%82%D0%BE%D1%80%D0%B0.JPG?alt=media&token=6cb45892-409d-4dfe-922a-8cbca6fe9691',
      },
      {
        title: 'Накладки на розширювачі арок ( задні ) 4 шт.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Desart%2Fdetails%2F%D0%A0%D0%BE%D0%B7%D1%88%D0%B8%D1%80%D1%8E%D0%B2%D0%B0%D1%87%D1%96%20%D0%B0%D1%80%D0%BE%D0%BA%20%D0%B7%D0%B0%D0%B4%D0%BDi.jpg?alt=media&token=0c6be91f-1c53-4beb-8d64-37d7fd2f32b8',
      },
      {
        title: 'Накладки на розширювачі арок ( Передні ) 4 шт.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Desart%2Fdetails%2F%D0%A0%D0%BE%D0%B7%D1%88%D0%B8%D1%80%D1%8E%D0%B2%D0%B0%D1%87%D1%96%20%D0%B0%D1%80%D0%BE%D0%BA%20%D0%BF%D0%B5%D1%80%D0%B5%D0%B4%D0%BDi.jpg?alt=media&token=5875a9c1-2ce8-4fc3-a10a-0c02cff731e3',
      },
      {
        title: 'Сплiттер ( задній бампер ) 1 шт.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Desart%2Fdetails%2F%D0%A1%D0%BF%D0%BBi%D1%82%D1%82%D0%B5%D1%80%20(%D0%B7%D0%B0%D0%B4%D0%BD%D1%96%D0%B8%CC%86%20%D0%B1%D0%B0%D0%BC%D0%BF%D0%B5%D1%80).jpg?alt=media&token=d7cdc7a3-e0ac-4461-89db-387080f941f3',
      },
      {
        title: 'Сплiттер ( передній бампер ) 1 шт.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Desart%2Fdetails%2F%D0%A1%D0%BF%D0%BBi%D1%82%D1%82%D0%B5%D1%80%20(%D0%BF%D0%B5%D1%80%D0%B5%D0%B4%D0%BD%D1%96%D0%B8%CC%86%20%D0%B1%D0%B0%D0%BC%D0%BF%D0%B5%D1%80).jpg?alt=media&token=878bc1ee-6b5a-4d55-92a0-ff72d0b5c651',
      },
      {
        title: 'Спойлер на дах 1 шт.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Desart%2Fdetails%2F%D0%A1%D0%BF%D0%BE%D0%B8%CC%86%D0%BB%D0%B5%D1%80%20%D0%BD%D0%B0%20%D0%B4%D0%B0%D1%85.jpg?alt=media&token=ada80a2e-130e-4e13-8d99-4c9ef7360460',
      },
    ],
    characteristics: [
      { title: '32', description: 'кількість елементів у комплекті' },
      { title: '55 кг', description: 'вага усіх деталей комплекту' },
      { title: 'скловолокно', description: 'склад деталей' },
    ],
    gallery: [
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Desart%2Fgallery%2FIMG_2934.jpg?alt=media&token=60ff16ed-fee5-4425-b14c-86ede97bd13c',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Desart%2Fgallery%2FIMG_2981.jpg?alt=media&token=f7529a6f-2bff-4dfa-ba27-a9fda998240e',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Desart%2Fgallery%2FIMG_2994.jpg?alt=media&token=d42fc836-33b1-4043-a9e3-aa85bcd99a50',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Desart%2Fgallery%2FIMG_3003.jpg?alt=media&token=cc68c8d7-a4e9-4433-8071-7b6813da1503',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Desart%2Fgallery%2FIMG_3018.jpg?alt=media&token=5b1dc754-6032-4029-936c-995692a14926',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Desart%2Fgallery%2FIMG_3095.jpg?alt=media&token=b31167a2-ee63-48bf-8d80-a834bcccc5e8',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Desart%2Fgallery%2FIMG_3105.jpg?alt=media&token=eea9fd1a-78ac-40af-8e7b-ad8a7af7c173',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Desart%2Fgallery%2FIMG_3095.jpg?alt=media&token=b31167a2-ee63-48bf-8d80-a834bcccc5e8',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Desart%2Fgallery%2FIMG_3108.jpg?alt=media&token=14702ed0-714e-4c29-8eff-68e1d7d281c2',
    ],
  },
  '/en/desart': {
    imgSrc: '/img/desart-main.png',
    imgBrand: '/img/desart-brand.svg',
    video: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/video%2Fdesart.MP4?alt=media&token=4c301c94-e1f6-48d5-aab1-3e40888eb853',
    title: 'Desart',
    description: 'DESART body kit for Mercedes G-Class',
    contentText: [
      'The historic flagship with a brutal character: a unique DESART body kit for the Mercedes G-Class',
      'The Mercedes G-Class is not just a car, it is a legend on wheels, a historical flagship in the world of cars. Brutal, strict, and possessing incredible energy, the G-Class has become a symbol of power and unparalleled style.',
      'Car Couture offers an exclusive DESART body kit for the Mercedes G-Class, which emphasizes its powerful and strict character, adding even more expressiveness and strength.',
      'DESART from Car Couture for the Mercedes G-Class is not just an external tuning, it is a true expression of Mercedes philosophy, combining tradition and innovation.',
    ],
    infoImgSrc: '/img/desart-info.png',
    details: [
      {
        title: 'Insert into glasses for headlights 2 pcs.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Desart%2Fdetails%2F%D0%92%D1%81%D1%82%D0%B0%D0%B2%D0%BA%D0%B0%20%D0%B2%20%D0%BE%D0%BA%D1%83%D0%BB%D1%8F%D1%80%D0%B8%20%D0%BD%D0%B0%20%D1%84%D0%B0%D1%80%D0%B8.jpg?alt=media&token=f3f3d45a-f68a-4a3d-b52d-6d3866419163',
      },
      {
        title: 'Side inserts in the front bumper 2 pcs.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Desart%2Fdetails%2F%D0%92%D1%81%D1%82%D0%B0%D0%B2%D0%BA%D0%B8%20%D0%B1%D1%96%D1%87%D0%BD%D1%96%20%D1%83%20%D0%BF%D0%B5%D1%80%D0%B5%D0%B4%D0%BD%D1%96%D0%B8%CC%86%20%D0%B1%D0%B0%D0%BC%D0%BF%D0%B5%D1%80.jpg?alt=media&token=bda66bc1-82a4-4ef8-8189-b1331704024a',
      },
      {
        title: 'Insert in the rear bumper 1 pc.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Desart%2Fdetails%2F%D0%92%D1%81%D1%82%D0%B0%D0%B2%D0%BA%D0%B8%20%D1%83%20%D0%B7%D0%B0%D0%B4%D0%BD%D1%96%D0%B8%CC%86%20%D0%B1%D0%B0%D0%BC%D0%BF%D0%B5%D1%80.jpg?alt=media&token=0ebab2ba-8bce-4658-acfb-23d115d343df',
      },
      {
        title: 'Rear bumper 1 pc.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Desart%2Fdetails%2F%D0%97%D0%B0%D0%B4%D0%BD%D1%96%D0%B8%CC%86%20%D0%B1%D0%B0%D0%BC%D0%BF%D0%B5%D1%80.jpg?alt=media&token=edd38999-5979-4ef5-b021-1bdfb9d6a714',
      },
      {
        title: 'Overlay on the roof ( under the roof ) 1 pc.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Desart%2Fdetails%2F%D0%9D%D0%B0%D0%BA%D0%BB%D0%B0%D0%B4%D0%BA%D0%B0%20%D0%BD%D0%B0%20%D0%B4%D0%B0%D1%85%20(%20%D0%BF%D1%96%D0%B4%20%D0%B4%D1%85%D0%B2%20)%201%20%D1%88%D1%82.JPG?alt=media&token=0708ab89-237e-453f-9f88-4123b3047ffa',
      },
      {
        title: 'Cover on the hood 1 pc.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Desart%2Fdetails%2F%D0%9D%D0%B0%D0%BA%D0%BB%D0%B0%D0%B4%D0%BA%D0%B0%20%D0%BD%D0%B0%20%D0%BA%D0%B0%D0%BF%D0%BE%D1%82.jpg?alt=media&token=75128969-be0e-4460-a8f7-fa4a7a27ac09',
      },
      {
        title: 'Threshold pads 4 pcs.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Desart%2Fdetails%2F%D0%9D%D0%B0%D0%BA%D0%BB%D0%B0%D0%B4%D0%BA%D0%B8%20%D0%BD%D0%B0%20%D0%BF%D0%BE%D1%80%D0%BE%D0%B3%D0%B8.jpg?alt=media&token=f9ad82b2-06f8-4e94-a450-5d13d46bb6c6',
      },
      {
        title: 'Arch extenders ( front ) 2 pcs.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Desart%2Fdetails%2F%D0%A0%D0%BE%D0%B7%D1%88%D0%B8%D1%80%D1%8E%D0%B2%D0%B0%D1%87%D1%96%20%D0%B0%D1%80%D0%BE%D0%BA%20%D0%BF%D0%B5%D1%80%D0%B5%D0%B4%D0%BDi.jpg?alt=media&token=5875a9c1-2ce8-4fc3-a10a-0c02cff731e3',
      },
      {
        title: 'Glasses for headlights 2 pcs.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Desart%2Fdetails%2F%D0%9E%D0%BA%D1%83%D0%BB%D1%8F%D1%80%D0%B8%20%D0%B4%D0%BB%D1%8F%20%D1%84%D0%B0%D1%80.jpg?alt=media&token=a3609af7-8615-4dc3-893a-cec4358bbd3f',
      },
      {
        title: 'Front bumper 1 pc.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Desart%2Fdetails%2F%D0%9F%D0%B5%D1%80%D0%B5%D0%B4%D0%BD%D1%96%D0%B8%CC%86%20%D0%B1%D0%B0%D0%BC%D0%BF%D0%B5%D1%80.jpg?alt=media&token=10061e7a-8d57-420d-8b72-0b3aa2f8a1b7',
      },
      {
        title: 'Radiator grill 1 pc.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Desart%2Fdetails%2F%D0%A0%D0%B5%D1%88i%D1%82%D0%BA%D0%B0%20%D1%80%D0%B0%D0%B4%D1%96%D0%B0%D1%82%D0%BE%D1%80%D0%B0.JPG?alt=media&token=6cb45892-409d-4dfe-922a-8cbca6fe9691',
      },
      {
        title: 'Pads on the arch extenders ( rear ) 4 pcs.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Desart%2Fdetails%2F%D0%A0%D0%BE%D0%B7%D1%88%D0%B8%D1%80%D1%8E%D0%B2%D0%B0%D1%87%D1%96%20%D0%B0%D1%80%D0%BE%D0%BA%20%D0%B7%D0%B0%D0%B4%D0%BDi.jpg?alt=media&token=0c6be91f-1c53-4beb-8d64-37d7fd2f32b8',
      },
      {
        title: 'Pads on the arch extenders ( Front ) 4 pcs.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Desart%2Fdetails%2F%D0%A0%D0%BE%D0%B7%D1%88%D0%B8%D1%80%D1%8E%D0%B2%D0%B0%D1%87%D1%96%20%D0%B0%D1%80%D0%BE%D0%BA%20%D0%BF%D0%B5%D1%80%D0%B5%D0%B4%D0%BDi.jpg?alt=media&token=5875a9c1-2ce8-4fc3-a10a-0c02cff731e3',
      },
      {
        title: 'Splitter ( rear bumper ) 1 pc.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Desart%2Fdetails%2F%D0%A1%D0%BF%D0%BBi%D1%82%D1%82%D0%B5%D1%80%20(%D0%B7%D0%B0%D0%B4%D0%BD%D1%96%D0%B8%CC%86%20%D0%B1%D0%B0%D0%BC%D0%BF%D0%B5%D1%80).jpg?alt=media&token=d7cdc7a3-e0ac-4461-89db-387080f941f3',
      },
      {
        title: 'Splitter ( front bumper ) 1 pc.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Desart%2Fdetails%2F%D0%A1%D0%BF%D0%BBi%D1%82%D1%82%D0%B5%D1%80%20(%D0%BF%D0%B5%D1%80%D0%B5%D0%B4%D0%BD%D1%96%D0%B8%CC%86%20%D0%B1%D0%B0%D0%BC%D0%BF%D0%B5%D1%80).jpg?alt=media&token=878bc1ee-6b5a-4d55-92a0-ff72d0b5c651',
      },
      {
        title: 'Roof spoiler 1 pc.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Desart%2Fdetails%2F%D0%A1%D0%BF%D0%BE%D0%B8%CC%86%D0%BB%D0%B5%D1%80%20%D0%BD%D0%B0%20%D0%B4%D0%B0%D1%85.jpg?alt=media&token=ada80a2e-130e-4e13-8d99-4c9ef7360460',
      },
    ],
    characteristics: [
      { title: '32', description: 'number of elements in the kit' },
      { title: '55 kg', description: 'total weight of all kit parts' },
      { title: 'fiberglass', description: 'composition of parts' },
    ],
    gallery: [
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Desart%2Fgallery%2FIMG_2934.jpg?alt=media&token=60ff16ed-fee5-4425-b14c-86ede97bd13c',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Desart%2Fgallery%2FIMG_2981.jpg?alt=media&token=f7529a6f-2bff-4dfa-ba27-a9fda998240e',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Desart%2Fgallery%2FIMG_2994.jpg?alt=media&token=d42fc836-33b1-4043-a9e3-aa85bcd99a50',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Desart%2Fgallery%2FIMG_3003.jpg?alt=media&token=cc68c8d7-a4e9-4433-8071-7b6813da1503',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Desart%2Fgallery%2FIMG_3018.jpg?alt=media&token=5b1dc754-6032-4029-936c-995692a14926',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Desart%2Fgallery%2FIMG_3095.jpg?alt=media&token=b31167a2-ee63-48bf-8d80-a834bcccc5e8',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Desart%2Fgallery%2FIMG_3105.jpg?alt=media&token=eea9fd1a-78ac-40af-8e7b-ad8a7af7c173',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Desart%2Fgallery%2FIMG_3095.jpg?alt=media&token=b31167a2-ee63-48bf-8d80-a834bcccc5e8',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Desart%2Fgallery%2FIMG_3108.jpg?alt=media&token=14702ed0-714e-4c29-8eff-68e1d7d281c2',
    ],
  },
  '/bentley-continental': {
    imgSrc: './img/bentley-continental-main.png',
    imgBrand: './img/bentley-brand.svg',
    video: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/video%2Fbentley-continental.MOV?alt=media&token=f8f538ce-55fb-41bf-8497-5ae2b2d2e936',
    title: 'Bentley',
    description: 'обваження для Bentley Continental Flying Spur',
    contentText: [
      'Втілення вищого рівня розкоші: унікальні обвіси для Bentley Continental Flying Spur.',
      'Bentley Continental Flying Spur – це символ найвищого рівня розкоші та елегантності у світі автомобілів. Власники цього преміального седана цінують витонченість, міць та неперевершений комфорт, який пропонує цей автомобіль.',
      'У Car Couture ми створили ексклюзивний обвіс для Bentley Continental Flying Spur, який підкреслює його велич та унікальний стиль. ',
      'Наш комплект призначений для тих, хто прагне досконалості і хоче надати своєму автомобілю ще більше витонченості та індивідуальності.',
    ],
    infoImgSrc: './img/bentley-continental-info.png',
    details: [
      {
        title: 'Сплiттер ( передній бампер ) 1 шт.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Continental%2Fdetails%2F%D0%92%D1%81%D1%82%D0%B0%D0%B2%D0%BA%D0%B8%20%D1%83%20%D1%80%D0%B5%D1%88%D1%96%D1%82%D0%BA%D1%83%20(%D0%BF%D0%B5%D1%80%D0%B5%D0%B4%D0%BD%D1%96%D0%B8%CC%86%20%D0%B1%D0%B0%D0%BC%D0%BF%D0%B5%D1%80).jpg?alt=media&token=cf37a9cb-e52e-482f-8734-9d4a9bc50385',
      },
      {
        title: 'Накладка ( задній бампер ) 4 шт.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Continental%2Fdetails%2F%D0%9D%D0%B0%D0%BA%D0%BB%D0%B0%D0%B4%D0%BA%D0%B0%20(%D0%B7%D0%B0%D0%B4%D0%BD%D1%96%D0%B8%CC%86%20%D0%B1%D0%B0%D0%BC%D0%BF%D0%B5%D1%80).jpg?alt=media&token=373d8837-c814-48b1-b085-fdb3d4a3c112',
      },
      {
        title: 'Накладка ( передній бампер ) 2 шт.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Continental%2Fdetails%2F%D0%9D%D0%B0%D0%BA%D0%BB%D0%B0%D0%B4%D0%BA%D0%B0%20(%D0%BF%D0%B5%D1%80%D0%B5%D0%B4%D0%BD%D1%96%D0%B8%CC%86%20%D0%B1%D0%B0%D0%BC%D0%BF%D0%B5%D1%80)%20.jpg?alt=media&token=11c540a5-a4fe-406c-8432-f6cd4b5d101c',
      },
      {
        title: 'Накладка на дзеркала 2 шт.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Continental%2Fdetails%2F%D0%9D%D0%B0%D0%BA%D0%BB%D0%B0%D0%B4%D0%BA%D0%B0%20%D0%BD%D0%B0%20%D0%B4%D0%B7%D0%B5%D1%80%D0%BA%D0%B0%D0%BB%D0%B0.jpg?alt=media&token=d65c37a0-1cdd-4a35-964f-b7b645650124',
      },
      {
        title: 'Накладки ( передні крила ) 2 шт.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Continental%2Fdetails%2F%D0%9D%D0%B0%D0%BA%D0%BB%D0%B0%D0%B4%D0%BA%D0%B8%20(%D0%BF%D0%B5%D1%80%D0%B5%D0%B4%D0%BD%D1%96%20%D0%BA%D1%80%D0%B8%D0%BB%D0%B0).JPG.jpg?alt=media&token=3b5379a9-8d9c-4336-b619-eac5447434ba',
      },
      {
        title: 'Накладки на пороги 4 шт.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Continental%2Fdetails%2F%D0%9D%D0%B0%D0%BA%D0%BB%D0%B0%D0%B4%D0%BA%D0%B8%20%D0%BD%D0%B0%20%D0%BF%D0%BE%D1%80%D0%BE%D0%B3%D0%B8%20.jpg?alt=media&token=31a6c082-64c2-47ef-99d1-d239b54c26ba',
      },
      {
        title: 'Сплiттер ( задній бампер ) 1 шт.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Continental%2Fdetails%2F%D0%A1%D0%BF%D0%BBi%D1%82%D1%82%D0%B5%D1%80%20(%D0%B7%D0%B0%D0%B4%D0%BD%D1%96%D0%B8%CC%86%20%D0%B1%D0%B0%D0%BC%D0%BF%D0%B5%D1%80)%20.jpg?alt=media&token=72352695-671d-4023-91e9-f979f9ebdcd1',
      },
      {
        title: 'Вставки у решітку ( передній бампер ) 4 шт.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Continental%2Fdetails%2F%D0%A1%D0%BF%D0%BBi%D1%82%D1%82%D0%B5%D1%80%20(%D0%BF%D0%B5%D1%80%D0%B5%D0%B4%D0%BD%D1%96%D0%B8%CC%86%20%D0%B1%D0%B0%D0%BC%D0%BF%D0%B5%D1%80).jpg?alt=media&token=67629b2c-e6e4-4ba4-bd57-51702c7e207d',
      },
      {
        title: 'Спойлер ( багажник ) 1 шт.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Continental%2Fdetails%2F%D0%A1%D0%BF%D0%BE%D0%B8%CC%86%D0%BB%D0%B5%D1%80%20(%D0%B1%D0%B0%D0%B3%D0%B0%D0%B6%D0%BD%D0%B8%D0%BA).jpg?alt=media&token=53476a2c-c92f-4c15-b852-2d536cee4cee',
      },
      {
        title: 'Спойлер ( заднє скло ) 1 шт.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Continental%2Fdetails%2F%D0%A1%D0%BF%D0%BE%D0%B8%CC%86%D0%BB%D0%B5%D1%80%20(%D0%B7%D0%B0%D0%B4%D0%BD%D1%94%20%D1%81%D0%BA%D0%BB%D0%BE)%20.jpg?alt=media&token=bed03215-31fc-47e9-9e95-3691aae0bc99',
      },
    ],
    characteristics: [
      { title: '20', description: 'кількість елементів у комплекті' },
      { title: '10 кг', description: 'вага усіх деталей комплекту' },
      { title: 'скловолокно', description: 'склад деталей' },
    ],
    gallery: [
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Continental%2Fgallery%2FIMG_4833.jpg?alt=media&token=6483a09e-ef3b-475b-bf78-bd2236a6568a',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Continental%2Fgallery%2FIMG_4835.jpg?alt=media&token=d4b1e125-37ec-4db8-b6f5-76c156d46193',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Continental%2Fgallery%2FIMG_4841.jpg?alt=media&token=35a5bddb-adea-4105-9c58-81a6fda2a45f',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Continental%2Fgallery%2FIMG_4842.jpg?alt=media&token=0965527f-21fd-4e17-aede-b3dbbc8e95ca',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Continental%2Fgallery%2FIMG_4843.jpg?alt=media&token=7a2abf21-6bb6-426e-b4ee-17e100c53235',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Continental%2Fgallery%2FIMG_4835.jpg?alt=media&token=d4b1e125-37ec-4db8-b6f5-76c156d46193',
    ],
  },
  '/en/bentley-continental': {
    imgSrc: '/img/bentley-continental-main.png',
    imgBrand: '/img/bentley-brand.svg',
    video: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/video%2Fbentley-continental.MOV?alt=media&token=f8f538ce-55fb-41bf-8497-5ae2b2d2e936',
    title: 'Bentley',
    description: 'Body kit for Bentley Continental Flying Spur',
    contentText: [
      'Embodiment of the highest level of luxury: unique body kit for Bentley Continental Flying Spur.',
      'The Bentley Continental Flying Spur is a symbol of the highest level of luxury and elegance in the automotive world. Owners of this magnificent sedan appreciate the refinement, power, and unparalleled comfort that offers this car.',
      'At Car Couture, we have created an exclusive body kit for the Bentley Continental Flying Spur that highlights its grandeur and unique style.',
      'Our kit is designed for those who seek perfection and want to add even more sophistication and individuality to their car.',
    ],
    infoImgSrc: '/img/bentley-continental-info.png',
    details: [
      {
        title: 'Splitter ( front bumper ) 1 pc.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Continental%2Fdetails%2F%D0%92%D1%81%D1%82%D0%B0%D0%B2%D0%BA%D0%B8%20%D1%83%20%D1%80%D0%B5%D1%88%D1%96%D1%82%D0%BA%D1%83%20(%D0%BF%D0%B5%D1%80%D0%B5%D0%B4%D0%BD%D1%96%D0%B8%CC%86%20%D0%B1%D0%B0%D0%BC%D0%BF%D0%B5%D1%80).jpg?alt=media&token=cf37a9cb-e52e-482f-8734-9d4a9bc50385',
      },
      {
        title: 'Cover ( rear bumper ) 4 pcs.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Continental%2Fdetails%2F%D0%9D%D0%B0%D0%BA%D0%BB%D0%B0%D0%B4%D0%BA%D0%B0%20(%D0%B7%D0%B0%D0%B4%D0%BD%D1%96%D0%B8%CC%86%20%D0%B1%D0%B0%D0%BC%D0%BF%D0%B5%D1%80).jpg?alt=media&token=373d8837-c814-48b1-b085-fdb3d4a3c112',
      },
      {
        title: 'Cover ( front bumper ) 2 pcs.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Continental%2Fdetails%2F%D0%9D%D0%B0%D0%BA%D0%BB%D0%B0%D0%B4%D0%BA%D0%B0%20(%D0%BF%D0%B5%D1%80%D0%B5%D0%B4%D0%BD%D1%96%D0%B8%CC%86%20%D0%B1%D0%B0%D0%BC%D0%BF%D0%B5%D1%80)%20.jpg?alt=media&token=11c540a5-a4fe-406c-8432-f6cd4b5d101c',
      },
      {
        title: 'Cover for mirrors 2 pcs.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Continental%2Fdetails%2F%D0%9D%D0%B0%D0%BA%D0%BB%D0%B0%D0%B4%D0%BA%D0%B0%20%D0%BD%D0%B0%20%D0%B4%D0%B7%D0%B5%D1%80%D0%BA%D0%B0%D0%BB%D0%B0.jpg?alt=media&token=d65c37a0-1cdd-4a35-964f-b7b645650124',
      },
      {
        title: 'Pads ( front fenders ) 2 pcs.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Continental%2Fdetails%2F%D0%9D%D0%B0%D0%BA%D0%BB%D0%B0%D0%B4%D0%BA%D0%B8%20(%D0%BF%D0%B5%D1%80%D0%B5%D0%B4%D0%BD%D1%96%20%D0%BA%D1%80%D0%B8%D0%BB%D0%B0).JPG.jpg?alt=media&token=3b5379a9-8d9c-4336-b619-eac5447434ba',
      },
      {
        title: 'Threshold pads 4 pcs.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Continental%2Fdetails%2F%D0%9D%D0%B0%D0%BA%D0%BB%D0%B0%D0%B4%D0%BA%D0%B8%20%D0%BD%D0%B0%20%D0%BF%D0%BE%D1%80%D0%BE%D0%B3%D0%B8%20.jpg?alt=media&token=31a6c082-64c2-47ef-99d1-d239b54c26ba',
      },
      {
        title: 'Splitter ( rear bumper ) 1 pc.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Continental%2Fdetails%2F%D0%A1%D0%BF%D0%BBi%D1%82%D1%82%D0%B5%D1%80%20(%D0%B7%D0%B0%D0%B4%D0%BD%D1%96%D0%B8%CC%86%20%D0%B1%D0%B0%D0%BC%D0%BF%D0%B5%D1%80)%20.jpg?alt=media&token=72352695-671d-4023-91e9-f979f9ebdcd1',
      },
      {
        title: 'Grille inserts ( front bumper ) 4 pcs.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Continental%2Fdetails%2F%D0%A1%D0%BF%D0%BBi%D1%82%D1%82%D0%B5%D1%80%20(%D0%BF%D0%B5%D1%80%D0%B5%D0%B4%D0%BD%D1%96%D0%B8%CC%86%20%D0%B1%D0%B0%D0%BC%D0%BF%D0%B5%D1%80).jpg?alt=media&token=67629b2c-e6e4-4ba4-bd57-51702c7e207d',
      },
      {
        title: 'Spoiler ( trunk ) 1 pc.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Continental%2Fdetails%2F%D0%A1%D0%BF%D0%BE%D0%B8%CC%86%D0%BB%D0%B5%D1%80%20(%D0%B1%D0%B0%D0%B3%D0%B0%D0%B6%D0%BD%D0%B8%D0%BA).jpg?alt=media&token=53476a2c-c92f-4c15-b852-2d536cee4cee',
      },
      {
        title: 'Spoiler ( rear window ) 1 pc.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Continental%2Fdetails%2F%D0%A1%D0%BF%D0%BE%D0%B8%CC%86%D0%BB%D0%B5%D1%80%20(%D0%B7%D0%B0%D0%B4%D0%BD%D1%94%20%D1%81%D0%BA%D0%BB%D0%BE)%20.jpg?alt=media&token=bed03215-31fc-47e9-9e95-3691aae0bc99',
      },
    ],
    characteristics: [
      { title: '20', description: 'number of items in the kit' },
      { title: '10 kg', description: 'weight of all kit components' },
      { title: 'fiberglass', description: 'composition of the components' },
    ],
    gallery: [
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Continental%2Fgallery%2FIMG_4833.jpg?alt=media&token=6483a09e-ef3b-475b-bf78-bd2236a6568a',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Continental%2Fgallery%2FIMG_4835.jpg?alt=media&token=d4b1e125-37ec-4db8-b6f5-76c156d46193',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Continental%2Fgallery%2FIMG_4841.jpg?alt=media&token=35a5bddb-adea-4105-9c58-81a6fda2a45f',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Continental%2Fgallery%2FIMG_4842.jpg?alt=media&token=0965527f-21fd-4e17-aede-b3dbbc8e95ca',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Continental%2Fgallery%2FIMG_4843.jpg?alt=media&token=7a2abf21-6bb6-426e-b4ee-17e100c53235',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Continental%2Fgallery%2FIMG_4835.jpg?alt=media&token=d4b1e125-37ec-4db8-b6f5-76c156d46193',
    ],
  },
  '/bentley-bentayga': {
    colors: [
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Bentayga%2Fcolors%2FIMG_4844.jpg?alt=media&token=a0a5893d-ec02-457e-ac7a-9c9f9c1ba910',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Bentayga%2Fcolors%2FIMG_4845.jpg?alt=media&token=d82287ec-5bb8-4391-9753-7822e0c97966',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Bentayga%2Fcolors%2FIMG_4846.jpg?alt=media&token=53495b29-12ae-46f6-bc44-35d03d0fd998',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Bentayga%2Fcolors%2FIMG_4849.jpg?alt=media&token=d291378f-38af-4335-a41c-a25addd14f8b',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Bentayga%2Fcolors%2FIMG_4850.jpg?alt=media&token=ebce535e-35a6-4692-a1e8-9f44632f2e53',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Bentayga%2Fcolors%2FIMG_4852.jpg?alt=media&token=18577af7-ffd4-445b-b7de-477a5a7877fe',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Bentayga%2Fcolors%2FIMG_4853.jpg?alt=media&token=6152cff7-c77c-41db-9906-f7bfe9156a0e',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Bentayga%2Fcolors%2FIMG_4854.jpg?alt=media&token=a69417b7-d8d9-43d8-bf93-c497750e1e41',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Bentayga%2Fcolors%2FIMG_4855.jpg?alt=media&token=00436609-e17e-4e69-aa64-cdc70fc9363d',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Bentayga%2Fcolors%2FIMG_4856.jpg?alt=media&token=f666a609-361b-4e80-ae09-bf8b055e2ce0',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Bentayga%2Fcolors%2FIMG_4857.jpg?alt=media&token=69970deb-a879-4aa8-90ba-b175a02a8b7e',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Bentayga%2Fcolors%2FIMG_4858.jpg?alt=media&token=582316bc-1aba-4422-825d-9a0282bb8fc8',
    ],
    imgSrc: './img/bentley-bentayga-main.png',
    imgBrand: './img/bentley-brand.svg',
    video: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/video%2Fbentley-bentayga.MOV?alt=media&token=8a8cab49-529f-4c1b-a176-2f13a44ac8f2',
    title: 'Bentley',
    description: 'унікальний обвіс для Bentley Bentayga',
    contentText: [
      'Перевага та розкіш: унікальний обвіс для Bentley Bentayga.',
      'Bentley Bentayga – це не просто автомобіль, це втілення розкоші та вишуканості. Власники Bentayga цінують неперевершену якість, увагу до деталей та міць, приховану за елегантними лініями цього позашляховика.',
      'У Car Couture ми розробили ексклюзивний обвіс для Bentley Bentayga, щоб підкреслити та посилити унікальні характеристики цієї моделі. Наш комплект ідеально інтегрується з естетикою автомобіля, додаючи агресивні акценти та посилюючи динамічний образ, властивий Bentayga.',
      'нАш обвіс для Bentley Bentayga - це не просто зовнішній тюнінг, це вираз філософії Bentley, що поєднує в собі традиції та інновації, розкіш і міць. Перетворіть свій Bentayga з нашим унікальним обважуванням і відчуйте справжню перевагу на дорозі',
    ],
    infoImgSrc: './img/bentley-bentayga-info.png',
    details: [
      {
        title: 'Сплiттер передній бампер 1 шт.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Bentayga%2Fdetails%2F%D0%93%D1%83%D0%B1%D0%B0%20%D0%BF%D0%B5%D1%80%D0%B5%D0%B4%D0%BD%D0%B8%D0%B8%CC%86%20%D0%B1%D0%B0%D0%BC%D0%BF%D0%B5%D1%80.jpg?alt=media&token=03a40fb5-8d28-4bc9-b932-f57c1fdc9d2b',
      },
      {
        title: 'Накладка на капот 1 шт.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Bentayga%2Fdetails%2F%D0%9D%D0%B0%D0%BA%D0%BB%D0%B0%D0%B4%D0%BA%D0%B0%20%D0%BD%D0%B0%20%D0%BA%D0%B0%D0%BF%D0%BE%D1%82.jpg?alt=media&token=a353b0fb-65dd-46ce-9c86-996d88738856',
      },
      {
        title: 'Накладки верхні ( двері + пер.крило ) 3 шт.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Bentayga%2Fdetails%2F%D0%9D%D0%B0%D0%BA%D0%BB%D0%B0%D0%B4%D0%BA%D0%B8%20%D0%B2%D0%B5%D1%80%D1%85%D0%BD%D1%96%20(%D0%B4%D0%B2%D0%B5%D1%80%D1%96%20%2B%20%D0%BF%D0%B5%D1%80.%D0%BA%D1%80%D0%B8%D0%BB%D0%BE).jpg?alt=media&token=6d6354b1-f22f-4296-983a-a6d137303194',
      },
      {
        title: 'Накладки на пороги ( складаються з 3 шт. ) 6 шт.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Bentayga%2Fdetails%2F%D0%9D%D0%B0%D0%BA%D0%BB%D0%B0%D0%B4%D0%BA%D0%B8%20%D0%BD%D0%B0%20%D0%BF%D0%BE%D1%80%D0%BE%D0%B3%D0%B8%20(%D1%81%D0%BA%D0%BB%D0%B0%D0%B4%D0%B0%D1%8E%D1%82%D1%8C%D1%81%D1%8F%20%D0%B7%203%20%D1%88%D1%82.%20%D0%A1%D1%82%D0%BE%D1%80%D0%BE%D0%BD%D0%B0).jpg?alt=media&token=31d41e40-1fee-4124-bcaf-7cde9e48f78f',
      },
      {
        title: 'Накладки низу дверей 4 шт.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Bentayga%2Fdetails%2F%D0%9D%D0%B0%D0%BA%D0%BB%D0%B0%D0%B4%D0%BA%D0%B8%20%D0%BD%D0%B0%20%D0%BF%D0%BE%D1%80%D0%BE%D0%B3%D0%B8.jpg?alt=media&token=064f1ef2-2121-4641-8f63-a2d5373e1cbb',
      },
      {
        title: 'Сплiттер задній бампер 1 шт.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Bentayga%2Fdetails%2F%D0%A1%D0%BF%D0%BBi%D1%82%D1%82%D0%B5%D1%80%20%D0%B7%D0%B0%D0%B4%D0%BD%D0%B8%D0%B8%CC%86%20%D0%B1%D0%B0%D0%BC%D0%BF%D0%B5%D1%80%20.jpg?alt=media&token=4aab0b62-54d8-44ba-ac4c-0221301a26b7',
      },
      {
        title: 'Спойлер багажника ( верх ) 1 шт. ',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Bentayga%2Fdetails%2F%D0%A1%D0%BF%D0%BE%D0%B8%CC%86%D0%BB%D0%B5%D1%80%20%D0%B1%D0%B0%D0%B3%D0%B0%D0%B6%D0%BD%D0%B8%D0%BA%D0%B0%20(%D0%B2%D0%B5%D1%80%D1%85).jpg?alt=media&token=0addfff4-8b5c-4d24-85e6-a9e39ab31383',
      },
      {
        title: 'Спойлер багажника ( низ ) 1 шт.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Bentayga%2Fdetails%2FIMG_9389-2.JPG?alt=media&token=ba6f3831-b7d2-470a-a3b5-fadf6a13db65',
      },
    ],
    characteristics: [
      { title: '32', description: 'кількість елементів у комплекті' },
      { title: '55 кг', description: 'вага усіх деталей комплекту' },
      { title: 'скловолокно', description: 'склад деталей' },
    ],
    gallery: [
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Bentayga%2Fgallery%2FIMG_2785.jpg?alt=media&token=d99cc771-5823-4745-a9a1-3185b5b3d6bf',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Bentayga%2Fgallery%2FIMG_5801.jpg?alt=media&token=a759d96e-23d5-482f-8e7e-edf0c02e11d7',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Bentayga%2Fgallery%2FIMG_5802.jpg?alt=media&token=c8145bdb-c4ae-4f6f-b692-94df5aeaf632',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Bentayga%2Fgallery%2FIMG_5803.jpg?alt=media&token=4216c2a0-7fa7-475b-b4e5-2fbd944f64fb',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Bentayga%2Fgallery%2F%D0%9D%D0%B0%D0%BA%D0%BB%D0%B0%D0%B4%D0%BA%D0%B0%20%D0%BD%D0%B0%20%D0%BA%D0%B0%D0%BF%D0%BE%D1%82.jpg?alt=media&token=720459ac-323b-4d16-9796-a5115658c31e',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Bentayga%2Fgallery%2F%D0%A1%D0%BF%D0%BE%D0%B8%CC%86%D0%BB%D0%B5%D1%80%20%D0%B1%D0%B0%D0%B3%D0%B0%D0%B6%D0%BD%D0%B8%D0%BA%D0%B0%20(%D0%B2%D0%B5%D1%80%D1%85).jpg?alt=media&token=546e3c33-d7af-4f0e-b729-6e8ee6dc259f',
    ],
  },
  '/en/bentley-bentayga': {
    colors: [
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Bentayga%2Fcolors%2FIMG_4844.jpg?alt=media&token=a0a5893d-ec02-457e-ac7a-9c9f9c1ba910',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Bentayga%2Fcolors%2FIMG_4845.jpg?alt=media&token=d82287ec-5bb8-4391-9753-7822e0c97966',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Bentayga%2Fcolors%2FIMG_4846.jpg?alt=media&token=53495b29-12ae-46f6-bc44-35d03d0fd998',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Bentayga%2Fcolors%2FIMG_4849.jpg?alt=media&token=d291378f-38af-4335-a41c-a25addd14f8b',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Bentayga%2Fcolors%2FIMG_4850.jpg?alt=media&token=ebce535e-35a6-4692-a1e8-9f44632f2e53',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Bentayga%2Fcolors%2FIMG_4852.jpg?alt=media&token=18577af7-ffd4-445b-b7de-477a5a7877fe',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Bentayga%2Fcolors%2FIMG_4853.jpg?alt=media&token=6152cff7-c77c-41db-9906-f7bfe9156a0e',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Bentayga%2Fcolors%2FIMG_4854.jpg?alt=media&token=a69417b7-d8d9-43d8-bf93-c497750e1e41',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Bentayga%2Fcolors%2FIMG_4855.jpg?alt=media&token=00436609-e17e-4e69-aa64-cdc70fc9363d',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Bentayga%2Fcolors%2FIMG_4856.jpg?alt=media&token=f666a609-361b-4e80-ae09-bf8b055e2ce0',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Bentayga%2Fcolors%2FIMG_4857.jpg?alt=media&token=69970deb-a879-4aa8-90ba-b175a02a8b7e',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Bentayga%2Fcolors%2FIMG_4858.jpg?alt=media&token=582316bc-1aba-4422-825d-9a0282bb8fc8',
    ],
    imgSrc: '/img/bentley-bentayga-main.png',
    imgBrand: '/img/bentley-brand.svg',
    video: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/video%2Fbentley-bentayga.MOV?alt=media&token=8a8cab49-529f-4c1b-a176-2f13a44ac8f2',
    title: 'Bentley',
    description: 'Unique body kit for Bentley Bentayga',
    contentText: [
      'Advantage and luxury: unique body kit for Bentley Bentayga.',
      'The Bentley Bentayga is not just a car; it embodies luxury and sophistication. Owners of the Bentayga appreciate the unmatched quality, attention to detail, and strength hidden behind the elegant lines of this SUV.',
      'At Car Couture, we have developed an exclusive body kit for the Bentley Bentayga to emphasize and enhance the unique characteristics of this model. Our kit integrates perfectly with the car`s aesthetics, adding aggressive accents and reinforcing the dynamic image  typical for the Bentayga.',
      'Our body kit for the Bentley Bentayga is not just exterior tuning; it is an expression of Bentley`s philosophy that combines tradition and innovation, luxury and strength. Transform your Bentayga with our unique body kit and experience true advantage on the road.',
    ],

    infoImgSrc: '/img/bentley-bentayga-info.png',
    details: [
      {
        title: 'Splitter front bumper 1 pc.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Bentayga%2Fdetails%2F%D0%93%D1%83%D0%B1%D0%B0%20%D0%BF%D0%B5%D1%80%D0%B5%D0%B4%D0%BD%D0%B8%D0%B8%CC%86%20%D0%B1%D0%B0%D0%BC%D0%BF%D0%B5%D1%80.jpg?alt=media&token=03a40fb5-8d28-4bc9-b932-f57c1fdc9d2b',
      },
      {
        title: 'Cover on the hood 1 pc.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Bentayga%2Fdetails%2F%D0%9D%D0%B0%D0%BA%D0%BB%D0%B0%D0%B4%D0%BA%D0%B0%20%D0%BD%D0%B0%20%D0%BA%D0%B0%D0%BF%D0%BE%D1%82.jpg?alt=media&token=a353b0fb-65dd-46ce-9c86-996d88738856',
      },
      {
        title: 'Upper overlays ( door + front wing ) 3 pcs.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Bentayga%2Fdetails%2F%D0%9D%D0%B0%D0%BA%D0%BB%D0%B0%D0%B4%D0%BA%D0%B8%20%D0%B2%D0%B5%D1%80%D1%85%D0%BD%D1%96%20(%D0%B4%D0%B2%D0%B5%D1%80%D1%96%20%2B%20%D0%BF%D0%B5%D1%80.%D0%BA%D1%80%D0%B8%D0%BB%D0%BE).jpg?alt=media&token=6d6354b1-f22f-4296-983a-a6d137303194',
      },
      {
        title: 'Threshold pads ( consisting of 3 pcs. ) 6 pcs.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Bentayga%2Fdetails%2F%D0%9D%D0%B0%D0%BA%D0%BB%D0%B0%D0%B4%D0%BA%D0%B8%20%D0%BD%D0%B0%20%D0%BF%D0%BE%D1%80%D0%BE%D0%B3%D0%B8%20(%D1%81%D0%BA%D0%BB%D0%B0%D0%B4%D0%B0%D1%8E%D1%82%D1%8C%D1%81%D1%8F%20%D0%B7%203%20%D1%88%D1%82.%20%D0%A1%D1%82%D0%BE%D1%80%D0%BE%D0%BD%D0%B0).jpg?alt=media&token=31d41e40-1fee-4124-bcaf-7cde9e48f78f',
      },
      {
        title: 'Door bottom linings 4 pcs.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Bentayga%2Fdetails%2F%D0%9D%D0%B0%D0%BA%D0%BB%D0%B0%D0%B4%D0%BA%D0%B8%20%D0%BD%D0%B0%20%D0%BF%D0%BE%D1%80%D0%BE%D0%B3%D0%B8.jpg?alt=media&token=064f1ef2-2121-4641-8f63-a2d5373e1cbb',
      },
      {
        title: 'Rear bumper splitter 1 pc.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Bentayga%2Fdetails%2F%D0%A1%D0%BF%D0%BBi%D1%82%D1%82%D0%B5%D1%80%20%D0%B7%D0%B0%D0%B4%D0%BD%D0%B8%D0%B8%CC%86%20%D0%B1%D0%B0%D0%BC%D0%BF%D0%B5%D1%80%20.jpg?alt=media&token=4aab0b62-54d8-44ba-ac4c-0221301a26b7',
      },
      {
        title: 'Trunk spoiler ( top ) 1 pc.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Bentayga%2Fdetails%2F%D0%A1%D0%BF%D0%BE%D0%B8%CC%86%D0%BB%D0%B5%D1%80%20%D0%B1%D0%B0%D0%B3%D0%B0%D0%B6%D0%BD%D0%B8%D0%BA%D0%B0%20(%D0%B2%D0%B5%D1%80%D1%85).jpg?alt=media&token=0addfff4-8b5c-4d24-85e6-a9e39ab31383',
      },
      {
        title: 'Trunk spoiler ( bottom ) 1 pc.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Bentayga%2Fdetails%2FIMG_9389-2.JPG?alt=media&token=ba6f3831-b7d2-470a-a3b5-fadf6a13db65',
      },
    ],
    characteristics: [
      { title: '32', description: 'number of items in the kit' },
      { title: '55 kg', description: 'weight of all kit components' },
      { title: 'fiberglass', description: 'composition of the components' },
    ],
    gallery: [
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Bentayga%2Fgallery%2FIMG_2785.jpg?alt=media&token=d99cc771-5823-4745-a9a1-3185b5b3d6bf',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Bentayga%2Fgallery%2FIMG_5801.jpg?alt=media&token=a759d96e-23d5-482f-8e7e-edf0c02e11d7',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Bentayga%2Fgallery%2FIMG_5802.jpg?alt=media&token=c8145bdb-c4ae-4f6f-b692-94df5aeaf632',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Bentayga%2Fgallery%2FIMG_5803.jpg?alt=media&token=4216c2a0-7fa7-475b-b4e5-2fbd944f64fb',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Bentayga%2Fgallery%2F%D0%9D%D0%B0%D0%BA%D0%BB%D0%B0%D0%B4%D0%BA%D0%B0%20%D0%BD%D0%B0%20%D0%BA%D0%B0%D0%BF%D0%BE%D1%82.jpg?alt=media&token=720459ac-323b-4d16-9796-a5115658c31e',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Bentley%20Bentayga%2Fgallery%2F%D0%A1%D0%BF%D0%BE%D0%B8%CC%86%D0%BB%D0%B5%D1%80%20%D0%B1%D0%B0%D0%B3%D0%B0%D0%B6%D0%BD%D0%B8%D0%BA%D0%B0%20(%D0%B2%D0%B5%D1%80%D1%85).jpg?alt=media&token=546e3c33-d7af-4f0e-b729-6e8ee6dc259f',
    ],
  },
  '/mercedes-benz': {
    imgSrc: './img/mercedes-benz-main.png',
    imgBrand: './img/mercedes-brand.svg',
    video: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/video%2Fmercedes-benz.MOV?alt=media&token=f2eff69d-d332-4899-90f5-3f81ac4c5174',
    title: 'Mercedes Benz',
    description: 'унікальний обвiс для Mercedes EQS',
    contentText: [
      'Революційний дизайн та інновації: унікальний обвiс для Mercedes EQS.',
      'Mercedes EQS - це автомобіль, який перевернув уявлення про сучасні електромобілі.',
      'Його революційний дизайн та передові технології зробили його еталоном у світі преміальних автомобілів.',
      'Компанія Car Couture пропонує ексклюзивне обважування для Mercedes EQS, яке підкреслює новаторський характер цього електромобіля і додає йому ще більше динаміки та елегантності.',
    ],
    infoImgSrc: './img/mercedes-benz-info.png',
    details: [
      {
        title: 'Бічні накладки ( заднього сплiттера ) 2 шт.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Mercedes%2Fdetails%2F%D0%91%D1%96%D1%87%D0%BD%D1%96%20%D0%BD%D0%B0%D0%BA%D0%BB%D0%B0%D0%B4%D0%BA%D0%B8%20(%20%D0%B7%D0%B0%D0%B4%D0%BD%D1%8C%D0%BE%D0%B3%D0%BE%20%D1%81%D0%BF%D0%BBi%D1%82%D1%82%D0%B5%D1%80%D0%B0%20).jpg?alt=media&token=b3cd43a6-0651-4810-ac6b-72e32608874f',
      },
      {
        title: 'Накладки на передній бампер 4 шт.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Mercedes%2Fdetails%2F%D0%91%D1%96%D1%87%D0%BD%D1%96%20%D0%BD%D0%B0%D0%BA%D0%BB%D0%B0%D0%B4%D0%BA%D0%B8%20(%20%D0%BF%D0%B5%D1%80%D0%B5%D0%B4%D0%BD%D1%8C%D0%BE%D0%B3%D0%BE%20%D1%81%D0%BF%D0%BBi%D1%82%D1%82%D0%B5%D1%80%D0%B0).jpg?alt=media&token=ace46787-95ec-4959-805e-7506e1fba0b7',
      },
      {
        title: 'Сплiттер ( задній бампер ) 1 шт.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Mercedes%2Fdetails%2F%D0%A1%D0%BF%D0%BBi%D1%82%D1%82%D0%B5%D1%80%20(%20%D0%B7%D0%B0%D0%B4%D0%BD%D1%96%D0%B8%CC%86%20%D0%B1%D0%B0%D0%BC%D0%BF%D0%B5%D1%80%20)%201%20%D1%88%D1%82..JPG?alt=media&token=75e761e3-05c1-46ba-b669-3820675b039c',
      },
      {
        title: 'Сплiттер ( передній бампер ) 1 шт.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Mercedes%2Fdetails%2F%D0%93%D1%83%D0%B1%D0%B0%20(%D0%BF%D0%B5%D1%80%D0%B5%D0%B4%D0%BD%D0%B8%D0%B8%CC%86%20%D0%B1%D0%B0%D0%BC%D0%BF%D0%B5%D1%80).jpg?alt=media&token=8827f1d9-b59d-4052-8727-38075c70904a',
      },
      {
        title: 'Накладка на задній бампер 2 шт.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Mercedes%2Fdetails%2F%D0%9D%D0%B0%D0%BA%D0%BB%D0%B0%D0%B4%D0%BA%D0%B0%20%D0%BD%D0%B0%20%D0%B7%D0%B0%D0%B4%D0%BD%D1%96%D0%B8%CC%86%20%D0%B1%D0%B0%D0%BC%D0%BF%D0%B5%D1%80.jpg?alt=media&token=0f2cc9a6-e3b1-43cb-a82f-c25694120314',
      },
      {
        title: 'Накладка під задній номер 1 шт.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Mercedes%2Fdetails%2FIMG_9450.JPG?alt=media&token=b37810af-1bcd-4602-9a90-780e6129110b',
      },
      {
        title: 'Бічні накладки на передній бампер',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Mercedes%2Fdetails%2F%D0%9D%D0%B0%D0%BA%D0%BB%D0%B0%D0%B4%D0%BA%D0%B8%20%D0%BD%D0%B0%20%D0%BF%D0%B5%D1%80%D0%B5%D0%B4%D0%BD%D1%96%D0%B8%CC%86%20%D0%B1%D0%B0%D0%BC%D0%BF%D0%B5%D1%80.JPG.jpg?alt=media&token=55243970-397d-43c6-b657-3b9b83f9382a',
      },
      {
        title: 'Спойлер ( заднє скло ) 1 шт.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Mercedes%2Fdetails%2F%D0%A1%D0%BF%D0%BE%D0%B8%CC%86%D0%BB%D0%B5%D1%80%20(%D0%B7%D0%B0%D0%B4%D0%BD%D1%94%20%D1%81%D0%BA%D0%BB%D0%BE).jpg?alt=media&token=d0dfd22b-f653-4bd5-848a-d1ea9797d4e4',
      },
      {
        title: 'Спойлер ( багажник ) 1 шт.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Mercedes%2Fdetails%2F%D0%A1%D0%BF%D0%BE%D0%B8%CC%86%D0%BB%D0%B5%D1%80%20(%D0%B1%D0%B0%D0%B3%D0%B0%D0%B6%D0%BD%D0%B8%D0%BA).jpg?alt=media&token=5a7730da-35aa-40bf-8783-3bd5a71fcea2',
      },
    ],
    characteristics: [
      { title: '15', description: 'кількість елементів у комплекті' },
      { title: '12 кг', description: 'вага усіх деталей комплекту' },
      { title: 'сколоволокно', description: 'склад деталей' },
    ],
    gallery: [
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Mercedes%2Fgallery%2FIMG_4815.jpg?alt=media&token=851e6211-9f64-4ba3-abd3-1629be6783c0',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Mercedes%2Fgallery%2FIMG_4816.jpg?alt=media&token=b1530eec-7292-43a0-8830-79ac5eca3c71',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Mercedes%2Fgallery%2FIMG_4818.jpg?alt=media&token=9d82fd89-fd35-4f88-88e0-f590bb5abe4b',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Mercedes%2Fgallery%2FIMG_4820.jpg?alt=media&token=579d1fd6-34eb-4521-9b97-4ea00d677d5c',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Mercedes%2Fgallery%2FIMG_4815.jpg?alt=media&token=851e6211-9f64-4ba3-abd3-1629be6783c0',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Mercedes%2Fgallery%2FIMG_4816.jpg?alt=media&token=b1530eec-7292-43a0-8830-79ac5eca3c71',
    ],
  },
  '/en/mercedes-benz': {
    imgSrc: '/img/mercedes-benz-main.png',
    imgBrand: '/img/mercedes-brand.svg',
    video: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/video%2Fmercedes-benz.MOV?alt=media&token=f2eff69d-d332-4899-90f5-3f81ac4c5174',
    title: 'Mercedes Benz',
    description: 'Unique body kit for Mercedes EQS',
    contentText: [
      'Revolutionary design and innovations: unique body kit for Mercedes EQS.',
      'The Mercedes EQS is a car that has revolutionized the concept of modern electric vehicles.',
      'Its revolutionary design and advanced technologies have made it a benchmark in the world of premium cars.',
      'Car Couture offers exclusive body styling for the Mercedes EQS, emphasizing the innovative nature of this electric vehicle and adding even more dynamics and elegance to it.',
    ],
    infoImgSrc: '/img/mercedes-benz-info.png',
    details: [
      {
        title: 'Side covers ( rear splitter ) 2 pcs.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Mercedes%2Fdetails%2F%D0%91%D1%96%D1%87%D0%BD%D1%96%20%D0%BD%D0%B0%D0%BA%D0%BB%D0%B0%D0%B4%D0%BA%D0%B8%20(%20%D0%B7%D0%B0%D0%B4%D0%BD%D1%8C%D0%BE%D0%B3%D0%BE%20%D1%81%D0%BF%D0%BBi%D1%82%D1%82%D0%B5%D1%80%D0%B0%20).jpg?alt=media&token=b3cd43a6-0651-4810-ac6b-72e32608874f',
      },
      {
        title: 'Pads on the front bumper 4 pcs.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Mercedes%2Fdetails%2F%D0%91%D1%96%D1%87%D0%BD%D1%96%20%D0%BD%D0%B0%D0%BA%D0%BB%D0%B0%D0%B4%D0%BA%D0%B8%20(%20%D0%BF%D0%B5%D1%80%D0%B5%D0%B4%D0%BD%D1%8C%D0%BE%D0%B3%D0%BE%20%D1%81%D0%BF%D0%BBi%D1%82%D1%82%D0%B5%D1%80%D0%B0).jpg?alt=media&token=ace46787-95ec-4959-805e-7506e1fba0b7',
      },
      {
        title: 'Splitter ( rear bumper ) 1 pc.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Mercedes%2Fdetails%2F%D0%A1%D0%BF%D0%BBi%D1%82%D1%82%D0%B5%D1%80%20(%20%D0%B7%D0%B0%D0%B4%D0%BD%D1%96%D0%B8%CC%86%20%D0%B1%D0%B0%D0%BC%D0%BF%D0%B5%D1%80%20)%201%20%D1%88%D1%82..JPG?alt=media&token=75e761e3-05c1-46ba-b669-3820675b039c',
      },
      {
        title: 'Splitter ( front bumper ) 1 pc.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Mercedes%2Fdetails%2F%D0%93%D1%83%D0%B1%D0%B0%20(%D0%BF%D0%B5%D1%80%D0%B5%D0%B4%D0%BD%D0%B8%D0%B8%CC%86%20%D0%B1%D0%B0%D0%BC%D0%BF%D0%B5%D1%80).jpg?alt=media&token=8827f1d9-b59d-4052-8727-38075c70904a',
      },
      {
        title: 'Cover on the rear bumper 2 pcs.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Mercedes%2Fdetails%2F%D0%9D%D0%B0%D0%BA%D0%BB%D0%B0%D0%B4%D0%BA%D0%B0%20%D0%BD%D0%B0%20%D0%B7%D0%B0%D0%B4%D0%BD%D1%96%D0%B8%CC%86%20%D0%B1%D0%B0%D0%BC%D0%BF%D0%B5%D1%80.jpg?alt=media&token=0f2cc9a6-e3b1-43cb-a82f-c25694120314',
      },
      {
        title: 'Cover under the back number 1 pc.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Mercedes%2Fdetails%2FIMG_9450.JPG?alt=media&token=b37810af-1bcd-4602-9a90-780e6129110b',
      },
      {
        title: 'Side panels on the front bumper',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Mercedes%2Fdetails%2F%D0%9D%D0%B0%D0%BA%D0%BB%D0%B0%D0%B4%D0%BA%D0%B8%20%D0%BD%D0%B0%20%D0%BF%D0%B5%D1%80%D0%B5%D0%B4%D0%BD%D1%96%D0%B8%CC%86%20%D0%B1%D0%B0%D0%BC%D0%BF%D0%B5%D1%80.JPG.jpg?alt=media&token=55243970-397d-43c6-b657-3b9b83f9382a',
      },
      {
        title: 'Spoiler ( rear window ) 1 pc.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Mercedes%2Fdetails%2F%D0%A1%D0%BF%D0%BE%D0%B8%CC%86%D0%BB%D0%B5%D1%80%20(%D0%B7%D0%B0%D0%B4%D0%BD%D1%94%20%D1%81%D0%BA%D0%BB%D0%BE).jpg?alt=media&token=d0dfd22b-f653-4bd5-848a-d1ea9797d4e4',
      },
      {
        title: 'Spoiler ( trunk ) 1 pc.',
        img: 'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Mercedes%2Fdetails%2F%D0%A1%D0%BF%D0%BE%D0%B8%CC%86%D0%BB%D0%B5%D1%80%20(%D0%B1%D0%B0%D0%B3%D0%B0%D0%B6%D0%BD%D0%B8%D0%BA).jpg?alt=media&token=5a7730da-35aa-40bf-8783-3bd5a71fcea2',
      },
    ],
    characteristics: [
      { title: '15', description: 'number of items in the kit' },
      { title: '12 kg', description: 'weight of all kit components' },
      { title: 'fiberglass', description: 'composition of the components' },
    ],
    gallery: [
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Mercedes%2Fgallery%2FIMG_4815.jpg?alt=media&token=851e6211-9f64-4ba3-abd3-1629be6783c0',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Mercedes%2Fgallery%2FIMG_4816.jpg?alt=media&token=b1530eec-7292-43a0-8830-79ac5eca3c71',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Mercedes%2Fgallery%2FIMG_4818.jpg?alt=media&token=9d82fd89-fd35-4f88-88e0-f590bb5abe4b',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Mercedes%2Fgallery%2FIMG_4820.jpg?alt=media&token=579d1fd6-34eb-4521-9b97-4ea00d677d5c',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Mercedes%2Fgallery%2FIMG_4815.jpg?alt=media&token=851e6211-9f64-4ba3-abd3-1629be6783c0',
      'https://firebasestorage.googleapis.com/v0/b/carcouture-6ed2e.appspot.com/o/Mercedes%2Fgallery%2FIMG_4816.jpg?alt=media&token=b1530eec-7292-43a0-8830-79ac5eca3c71',
    ],
  },
};
