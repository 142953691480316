import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Inputs from '../../components/Inputs/Inputs';
import Links from '../../components/Links/Links';
import { Context } from '../../Context';
import { pageData } from './ItemInfo';
import Colors from '../../components/Colors/Colors';
import { useSwipeable } from 'react-swipeable';
import './Item.css';

const translations = {
  укр: {
    detailsTitle: 'деталі що входять до складу:',
    characteristicsTitle: 'Характеристики обвісів',
    videoTitle: 'відеоогляд',
    videoDescription: 'відеоогляд машини:',
    itemInfoData: [
      { label: 'Наявність', value: 'В наявності' },
      { label: 'Вартість фарбування та монтажу', value: 'Входить до вартості обвісу' },
      { label: 'Персоналізація', value: 'Індивідуальний підхід та\n унікальне рішення саме для вас' },
      { label: 'де відбувається монтаж', value: 'Монтаж можливий у вашому\n місті' },
      { label: 'гарантія', value: 'надаємо гарантію як на сам\n виріб так і на його встановлення' },
    ],
  },
  eng: {
    detailsTitle: 'Details included:',
    characteristicsTitle: 'Features of kits',
    videoTitle: 'Video review',
    videoDescription: 'Car video review:',
    itemInfoData: [
      { label: 'Availability', value: 'In stock' },
      { label: 'Painting and installation cost', value: 'Included in the trim cost' },
      { label: 'Personalization', value: 'Individual approach and\n unique solution just for you' },
      { label: 'where the installation takes place', value: 'Installation possible in your\n city' },
      { label: 'warranty', value: 'we provide a warranty for both the\n product and its installation' },
    ],
  },
};

export default function Item() {
  const { language } = useContext(Context);
  const location = useLocation();
  const currentPage = location.pathname;
  const data = pageData[currentPage];
  const t = translations[language];

  const [info, setInfo] = useState(false);

  useEffect(() => {
    setInfo(data.details[0]);
    // eslint-disable-next-line
  }, [location]);

  const handleNextSlide = () => {
    const currentIndex = data.details.findIndex((detail) => detail.title === info.title);
    const nextIndex = (currentIndex + 1) % data.details.length;
    setInfo(data.details[nextIndex]);
  };

  const [page, setPage] = useState(0);
  const photosPerPage = 3;
  const totalPages = Math.ceil(data.gallery.length / photosPerPage);

  const startIndex = page * photosPerPage;
  const currentPhotos = data.gallery.slice(startIndex, startIndex + photosPerPage);

  const handlers = useSwipeable({
    onSwipedLeft: () => setPage((prevPage) => (prevPage < totalPages - 1 ? prevPage + 1 : prevPage)),
    onSwipedRight: () => setPage((prevPage) => (prevPage > 0 ? prevPage - 1 : prevPage)),
  });

  const handleGalleryClick = () => {
    setPage((prevPage) => (prevPage < totalPages - 1 ? prevPage + 1 : 0));
  };

  return (
    <div className="item">
      <div className="item__img" onClick={handleNextSlide}>
        <img className="item__img-item" src={data.imgSrc} alt={data.title} />
      </div>
      <h1 className="item__title">{data.title}</h1>

      <div className="item__content">
        <div className="item__content-logo">
          <img className="item__content-logo-img" src={data.imgBrand} alt="brand" />
        </div>

        <div className="item__content-info">
          <div className="item__content-info-text">
            {data.contentText.map((text, index) => (
              <p key={index}>{text}</p>
            ))}
          </div>
          <div className="item__content-info-text-img">
            <img src={data.infoImgSrc} alt="info" />
          </div>
        </div>
      </div>

      <div className="item__wrapper">
        <h2 className="item__details-title">{t.detailsTitle}</h2>
        <div className="item__details-wrapper">
          <div className="item__details-img">
            <img src={info.img} alt="detail" onClick={handleNextSlide} />
          </div>

          <div className="item__details-info">
            <ul className="item__details-info-main">
              {data.details.map((detail, index) => (
                <li
                  className={`item__details-info-main-item ${detail.title === info.title ? 'item__details-info-main-active' : ''}`}
                  onClick={() => {
                    setInfo(detail);
                  }}
                  key={index}
                >
                  {detail.title}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="item__characteristic-title">{t.characteristicsTitle}</div>
        <div className="item__characteristic-description">{data.description}</div>
        <div className="item__characteristic-wrapper">
          {data.characteristics.map((char, index) => (
            <div key={index} className="item__characteristic-wrapper-item">
              <div className="item__characteristic-wrapper-title">{char.title}</div>
              <div className="item__characteristic-wrapper-description">{char.description}</div>
            </div>
          ))}
        </div>

        <div className="item__info">
          {t.itemInfoData.map((item, index) => (
            <div className="item__info-wrapper" key={index}>
              <p>{item.label}</p>
              <div className="item__info-wrapper-line"></div>
              <p>
                {item.value.split('\n').map((line, idx) => (
                  <span key={idx}>
                    {line}
                    <br />
                  </span>
                ))}
              </p>
            </div>
          ))}
        </div>

        <div {...handlers} className="item__gallery">
          <div className="item__gallery-wrapper" onClick={handleGalleryClick}>
            <div className="item__gallery-left">
              {currentPhotos.slice(0, 2).map((src, index) => (
                <div key={index} className="item__gallery-left-img">
                  <img src={src} alt="foto" />
                </div>
              ))}
            </div>
            <div className="item__gallery-right">{currentPhotos[2] && <img src={currentPhotos[2]} alt="foto" />}</div>
          </div>

          <div className="item__gallery-navigate">
            {Array.from({ length: totalPages }).map((_, pageIndex) => (
              <div key={pageIndex} onClick={() => setPage(pageIndex)} className={`item__gallery-navigate-item ${pageIndex === page ? 'item__gallery-navigate-item-active' : ''}`} />
            ))}
          </div>
        </div>

        {data.colors && <Colors data={data.colors} />}

        <div className="item__video">
          <div className="item__video-title">{t.videoTitle}</div>
          <div className="item__video-description">{t.videoDescription}</div>
          <video src={data.video} className="item__video-video" controls></video>
        </div>
        <Inputs />
        <Links />
      </div>
    </div>
  );
}
