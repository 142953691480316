import React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import './Container.css';

export default function Container({ children }) {

  return (
    <div className="container">
      <Header />
      {children}
      <Footer />
    </div>
  );
}
